import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import styles from './index.module.scss';
import Input from '../../../components/Input';
import StoreContext from '../../../state/context/store';
import { login, setMe } from '../../../state/actions/me';
import { rootUrl } from '../../../config';
import { Button } from '@material-ui/core';

const ResetPassword = (props) => {
  const { history } = props;
  const { t } = useTranslation();
  const [, dispatch] = useContext(StoreContext);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const token = history.location.pathname.split('/').slice(-1).pop();

  const reset = async () => {
    await axios
      .post(
        `${rootUrl}/api/reset-pod-password`,
        {
          token,
          password,
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          login(dispatch, response.data);
          setMe(dispatch, response.data);
          history.push(`/profile/${response.data.id}`);
        }
      })
      .catch((error, sdf) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          setPasswordError(error.response.data.error);
        } else {
          setPasswordError('There was an unknown error.  Please try again');
        }
      });
  };

  const save = () => {
    if (!password) {
      return setPasswordError('A password is required');
    }
    if (password.length < 8) {
      return setPasswordError(
        'Your password must be at least 8 characters long'
      );
    }
    if (password !== confirmPassword) {
      return setPasswordError('Your passwords do not match');
    }
    reset();
  };

  return (
    <div className={styles.container}>
      <div className={styles.image}></div>
      <h1 className={styles.title}>{t('Reset your password')}</h1>
      <form className={styles.form}>
        <label htmlFor="email">{t('Password')}</label>
        <Input
          type="password"
          maxLength={255}
          id="pw"
          onChange={(e) => {
            setPasswordError('');
            setPassword(e.target.value);
          }}
          value={password}
        />
        <label htmlFor="pw2">{t('Confirm Password')}</label>
        <Input
          type="password"
          maxLength={255}
          id="pw2"
          value={confirmPassword}
          onChange={(e) => {
            setPasswordError('');
            setConfirmPassword(e.target.value);
          }}
          error={t(passwordError)}
        />
      </form>

      <div className={styles.login}>
        <Button
          className={`${styles.purpleButton} ${styles.resetButton}`}
          onClick={save}
        >
          {t('Reset')}
        </Button>
        <div className={styles.privacyWrapper}>
          <a
            className={styles.privacy}
            style={{ marginLeft: 'auto' }}
            href="mailto:pods@500womenscientists.org"
            rel="noopener noreferrer"
          >
            {t('Contact Us')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
