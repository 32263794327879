// @flow
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import styles from './index.module.scss';
import StoreContext from '../../../state/context/store';
import FTGLogo from '../../../assets/images/gap/FTG-Logo.svg';
import Logo500WS from '../../../assets/images/gap/500WS-small.svg';
import { LayoutContext } from '../../../state/context/layout';
// import xIcon from '../../../assets/images/gap/popupX.svg';
import InfoIcon from '../../../assets/images/gap/infoIcon.svg';
import ChartIcon from '../../../assets/images/gap/chartIcon.svg';
import ContributeIcon from '../../../assets/images/gap/contributeIcon.svg';
import MenuIcon from '../../../assets/images/gap/menuIcon.svg';

const Header = (props) => {
  const { history } = props;
  const [context, dispatch] = useContext(StoreContext);
  const { phoneActive, tabletActive } = useContext(LayoutContext);
  const { t, i18n } = useTranslation();
  const [active, setActive] = useState('/');
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [initY, setInitY] = useState();

  //attempt to retrieve language from session
  useEffect(() => {
    const storedLanguage = sessionStorage.getItem('language');
    if (storedLanguage !== i18n.language) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]); // eslint-disable-line

  useEffect(() => {
    setActive(window.location.pathname);
  }, [window.location.pathname]);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    sessionStorage.setItem('language', language);
  };

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

  const closeMenu = () => {
    setNavbarOpen(false);
  };

  const handleTouchStart = (event) => {
    // console.log();
    setInitY(event.changedTouches[0].screenY);
  };

  const handleTouchEnd = (event) => {
    // console.log(event.changedTouches[0].screenY);
    if (initY < event.changedTouches[0].screenY) {
      closeMenu();
    }
  };

  return (
    <>
      <nav className={styles.topnav}>
        <div className={styles.menu}>
          <nav className="navBar">
            {/* <button onClick={handleToggle}>
              {navbarOpen ? 'Close' : 'Open'}
            </button> */}
            <img
              src={MenuIcon}
              className={styles.menuIcon}
              onClick={handleToggle}
            />
            <div
              className={`${styles.menuNav} ${
                navbarOpen ? styles.showMenu : ''
              }`}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              <div className={styles.slideIndicator}></div>
              <a
                className={`${active == '/about' ? styles.active : ''}`}
                href="./about"
                // onClick={() => closeMenu()}
              >
                <img src={InfoIcon} />
                <div>{t('About Fix the Gap')}</div>
              </a>
              <Link
                // className={`${active === '/' ? styles.active : ''}`}
                to="./"
                onClick={() => closeMenu()}
              >
                <img src={ChartIcon} />
                <div>{t('Explore Database')}</div>
              </Link>
              <a
                // className={`${active == '/contribute' ? styles.active : ''}`}
                // to="./contribute"
                href="https://docs.google.com/forms/d/e/1FAIpQLSf0_YPcWNMsMJDD1OxJfGf5lgSKbjrNSQkf_dL0wfDCATU_Gw/viewform"
                // onClick={() => closeMenu()}
              >
                <img src={ContributeIcon} />
                <div>{t('Contribute to Database')}</div>
              </a>
              {/* <Link
                to="./feedback"
                onClick={() => closeMenu()}
              >
                <img src={ChatIcon} />
                <div>{t('User Feedback')}</div>
              </Link> */}
            </div>
          </nav>
        </div>
        <div className={styles.left}>
          {!phoneActive && !tabletActive ? (
            <>
              <img
                src={FTGLogo}
                alt="Fix The Gap"
                className={styles.gapLogo}
                useMap="#logo-map"
              />
              <img
                src={Logo500WS}
                alt="500 Women Scientists"
                className={styles.logo500ws}
                useMap="#logo-map"
              />
              <map name="logo-map">
                <area
                  shape="rect"
                  coords="0,0,220,115"
                  href="/"
                  alt="Gage Home"
                />
                <area
                  shape="rect"
                  coords="220,0,350,115"
                  href="//500womenscientists.org"
                  target="_blank"
                  alt="500ws Home"
                />
              </map>
            </>
          ) : (
            <div className={styles.smallLogo}>
              <img
                src={FTGLogo}
                alt="Fix The Gap"
                className={styles.gapLogo}
                onClick={() => {
                  history.push('/');
                }}
              />
              <img
                src={Logo500WS}
                alt="500 Women Scientists"
                className={styles.logo500ws}
                useMap="#logo-map"
              />
            </div>
          )}
        </div>
        <div className={styles.right}>
          <Link
            className={`${active == '/about' ? styles.active : ''}`}
            to="./about"
          >
            {t('About')}
          </Link>
          <Link className={`${active === '/' ? styles.active : ''}`} to="./">
            {t('Explore Database')}
          </Link>
          <a
            className={`${active == '/contribute' ? styles.active : ''}`}
            href="https://docs.google.com/forms/d/e/1FAIpQLSf0_YPcWNMsMJDD1OxJfGf5lgSKbjrNSQkf_dL0wfDCATU_Gw/viewform"
          >
            {t('Contribute')}
          </a>
          {/* <Link
            className={`${active == '/feedback' ? styles.active : ''}`}
            to="./feedback"
          >
            {t('Feedback')}
          </Link> */}
        </div>
        {navbarOpen && (
          <div className={styles.cover} onClick={() => closeMenu()}></div>
        )}
      </nav>
    </>
  );
};

export default withRouter(Header);
