import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import Pods from './Pods';
// import Portal from './Portal';
import Gap from './Gap';
import './i18n';
import * as serviceWorker from './serviceWorker';
import './index.css';

/* 
    TO DO: build based on env variable
    Currently, comment out undesired app
      App: Gage/Request Application
      Pods: Pods Application
*/

// if (process.env.REACT_APP_BUILD_APP === 'gap') {
ReactDOM.render(<Gap />, document.getElementById('root'));
// } else if (process.env.REACT_APP_BUILD_APP === 'pods') {
//   ReactDOM.render(<Pods />, document.getElementById('root'));
// } else {
//   console.log(process.env);
//   ReactDOM.render(<App />, document.getElementById('root'));
// }

//ReactDOM.render(<Portal />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
