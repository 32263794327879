/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './index.scss';
import StoreContext from '../../state/context/store';
import SearchBox from '../SearchBox';
import { withRouter } from 'react-router-dom';
import filterIcon from '../../assets/images/Filter.svg';
//import bookmarkIcon from '../../assets/images/Saved.svg';
import '@fortawesome/fontawesome-free';
import 'components-font-awesome';
import { rootUrl } from '../../config';
import Filters from '../Filters';
import MetricsManager from '../../service/metrics';
import { LayoutContext } from '../../state/context/layout';
import { Button, Divider, Grid, IconButton } from '@material-ui/core';

import {
  setSearchResults,
  setSearchTerm,
  toggleFilterButton,
  setSelectedSpecialization,
  setSelectedDisciplines,
  setSelectedMedicalDisciplines,
  setSelectedMedicalSubDisciplines,
  setSelectedLocations,
  setSelectedLocationText,
  setSelectedInterests,
  setSelectedRaces,
  setSelectedGenders,
  setSelectedLanguages,
  setSearching,
  setActiveDisplay,
} from '../../state/actions/search';

const mm = MetricsManager.getInstance();

const SearchBar = (props) => {
  const {
    setSearchSubmitted,
    searchSubmitted,
    toggleFilters,
    drawerMax,
    setSearchMsg,
    expandedText,
    setExpandedText,
  } = props;

  const [store, dispatch] = useContext(StoreContext);
  const { phoneActive, tabletActive } = useContext(LayoutContext);
  const { search } = store;
  const {
    filterButtonVisible,
    selectedSpecialization,
    selectedDisciplines,
    selectedMedicalDisciplines,
    selectedMedicalSubDisciplines,
    selectedLocations,
    selectedInterests,
    selectedRaces,
    selectedGenders,
    selectedLanguages,
    activeDisplay,
  } = search;

  const [searchValue, setSearchValue] = useState(search.searchTerm);
  const [expanded, setExpanded] = useState(false);
  const [displayMore, setDisplayMore] = useState(false);

  const searchRef = React.createRef();

  const { t, i18n } = useTranslation();

  const clear = () => {
    clearSearchText();

    setSelectedSpecialization(dispatch, '');
    setSelectedDisciplines(dispatch, []);
    setSelectedMedicalDisciplines(dispatch, []);
    setSelectedMedicalSubDisciplines(dispatch, []);
    setSelectedLocations(dispatch, []);
    setSelectedLocationText(dispatch, '');
    setSelectedInterests(dispatch, []);
    setSelectedRaces(dispatch, []);
    setSelectedGenders(dispatch, []);
    setSelectedLanguages(dispatch, []);
    setActiveDisplay(dispatch, null);
    if (store.map && store.map.map && store.map.map.state.map) {
      setTimeout(() => {
        store.map.map.state.map.resize();
      }, 500);
    }
  };

  const clearSearchText = () => {
    setSearchResults(dispatch, []);
    setSearchValue('');
    setSearchTerm(dispatch, '');
    setDisplayMore(false);
    setExpandedText(false);
    setSearchSubmitted(false);
    setSearching(dispatch, false);
  };

  const searchGage = () => {
    setSearchResults(dispatch, []);
    const start = new Date().getTime();
    setSearching(dispatch, true);
    setSearchSubmitted(true);
    setExpandedText(false);

    axios
      .post(
        `${rootUrl}/api/search`,
        {
          search: searchValue,
          specialization: selectedSpecialization,
          disciplines: selectedDisciplines,
          medicalDisciplines: selectedMedicalDisciplines,
          medicalSubDisciplines: selectedMedicalSubDisciplines,
          locations: selectedLocations,
          interests: selectedInterests,
          races: selectedRaces,
          genders: selectedGenders,
          languages: selectedLanguages,
        },
        { withCredentials: true }
      )
      .then((results) => {
        setSearchTerm(dispatch, searchValue);
        setActiveDisplay(dispatch, 'search');
        //if search is too large, warn user
        if (results.data.error) {
          setSearchMsg(
            `${t('search.tooLarge_part1')} ${Number(
              results.data.total
            ).toLocaleString(undefined, {
              minimumFractionDigits: 0,
            })} ${t('search.tooLarge_part2')}.`
          );
          return;
        } else {
          setSearchMsg('');
        }

        setSearchResults(dispatch, results.data);

        mm.addMetrics(
          results.data.map((r) => {
            return {
              userId: r.userId,
              appId: 1, //gage
              gageProfileId: r.id,
              podProfileId: null,
              metric: 'search',
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSearching(dispatch, false);
        const time = new Date().getTime() - start;
        if (store.me.tracking) {
          ReactGA.timing({
            category: 'Search',
            variable: 'load',
            value: time, // in milliseconds
            label: 'Search API',
          });
        }
      });
    if (store.me.tracking) {
      ReactGA.event({
        category: 'Search',
        action: 'Term',
        label: searchValue,
      });
    }
  };

  useEffect(() => {
    if (
      selectedSpecialization ||
      selectedDisciplines.length ||
      selectedMedicalDisciplines.length ||
      selectedMedicalSubDisciplines.length ||
      selectedLocations.length ||
      selectedInterests.length ||
      selectedRaces.length ||
      selectedGenders.length ||
      selectedLanguages.length ||
      searchSubmitted
    ) {
      searchGage();
    }
  }, [
    selectedSpecialization,
    selectedDisciplines,
    selectedMedicalDisciplines,
    selectedMedicalSubDisciplines,
    selectedLocations,
    selectedInterests,
    selectedRaces,
    selectedGenders,
    selectedLanguages,
  ]); // eslint-disable-line

  // 1 time only, if we already have a search term, go ahead and kick off search (pulled from storage)
  useEffect(() => {
    if (searchValue) {
      searchGage();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (activeDisplay) {
      //make sure map still fits
      if (store.map && store.map.map && store.map.map.state.map) {
        setTimeout(() => {
          store.map.map.state.map.resize();
        }, 500);
      }
    }
  }, [activeDisplay]); // eslint-disable-line

  useEffect(() => {
    if (phoneActive || tabletActive) {
      setExpandedText(false);
    }
  }, [phoneActive, tabletActive]); // eslint-disable-line

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (e.target.value) {
        searchGage();
      } else {
        clearSearchText();
      }
    }
  };

  const onInput = (e) => {
    if (!(phoneActive || tabletActive)) {
      const input = e.target;
      if (!displayMore) {
        setDisplayMore(input.offsetWidth < input.scrollWidth);
        setExpandedText(input.offsetWidth < input.scrollWidth);
      }
    }
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    !e.target.value && setExpandedText(false);
    !e.target.value && setDisplayMore(false);
  };

  //create listener on our filter list to determine need for button
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentBoxSize) {
          // Firefox implements `contentBoxSize` as a single content rect, rather than an array
          const contentBoxSize = Array.isArray(entry.contentBoxSize)
            ? entry.contentBoxSize[0]
            : entry.contentBoxSize;

          const containerWidth = contentBoxSize.inlineSize;
          let childrenWidth = 0;

          for (let i = 0; i < entry.target.children.length; i++) {
            childrenWidth += entry.target.children[i].offsetWidth;
          }
          console.log(
            filterButtonVisible,
            store.search.filterButtonVisible,
            window.filterButtonVisible
          );

          const shouldHideButton =
            Math.round(childrenWidth) > Math.round(containerWidth);

          //having a hard time with context. Using root
          if (window.filterButtonVisible !== shouldHideButton) {
            window.filterButtonVisible = shouldHideButton;
            toggleFilterButton(dispatch, shouldHideButton);
          }
        }
      }
    }); // eslint-disable-line

    const node = document.getElementsByClassName('search-wrapper');

    resizeObserver.observe(node[0]);

    return () => {
      resizeObserver.disconnect();
    };
  }, []); // eslint-disable-line

  const BuildButton = useMemo(() => {
    return filterButtonVisible ? (
      <Button
        variant="contained"
        color="primary"
        className="filter-button"
        onClick={() => setExpanded(!expanded)}
        endIcon={
          expanded && filterButtonVisible ? (
            <i className="fas fa-chevron-down"></i>
          ) : (
            <i className="fas fa-chevron-up"></i>
          )
        }
      >
        {t('All Filters')}
      </Button>
    ) : null;
  }, [filterButtonVisible, expanded, setExpanded, i18n.language]); // eslint-disable-line

  return (
    <div
      className={`search-container ${
        activeDisplay === 'bookmark' || drawerMax ? 'hidden' : ''
      }`}
      tabIndex={0}
      aria-label="Search Actions"
    >
      {/* <div className={styles.title}>{t('Search for a Woman in STEMM')}:</div> */}

      <Grid container className="grid" spacing={0}>
        <Grid
          item
          xs={phoneActive || tabletActive ? null : 9}
          className={`search-wrapper ${
            expanded && filterButtonVisible ? 'expanded' : ''
          }`}
          aria-label="Search and Filters"
        >
          <div ref={searchRef} style={{ width: '100%' }} className="searchBox">
            <SearchBox
              placeholder={t('Enter Keyword/City/Discipline, etc')}
              value={searchValue}
              clear={clearSearchText}
              displayMore={displayMore}
              setDisplayMore={setDisplayMore}
              onChange={handleChange}
              onKeyDown={onKeyDown}
              onInput={onInput}
              search={searchGage}
              expanded={expandedText}
              setExpanded={setExpandedText}
            />
          </div>
          {!phoneActive && !tabletActive && <Filters />}
        </Grid>
        <Grid
          item
          xs={phoneActive || tabletActive ? null : 3}
          className="button-wrapper"
          aria-label="Expand Search Actions and Bookmarks"
        >
          {(phoneActive || tabletActive) && (
            <IconButton className={'filter-button'} onClick={toggleFilters}>
              <img src={filterIcon} alt="Filters" aria-label="Filters" />
            </IconButton>
          )}
          {!phoneActive && !tabletActive && (
            <>
              <Divider orientation="vertical" className="divider" flexItem />
              {BuildButton}
              <Button
                className="clear-button"
                onClick={clear}
                variant="text"
                disableRipple
              >
                {t('Clear')}
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(SearchBar);
