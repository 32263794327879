import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from 'react';
import axios from 'axios';
import ReactMapboxGl, {
  ZoomControl,
  Cluster,
  Layer,
  Source,
  Feature,
  Marker,
  RotationControl,
} from 'react-mapbox-gl';
import { rootUrl } from '../../config';
import StoreContext from '../../state/context/store';
import styles from './map.module.scss';
import LeftPanel from './left-panel';
import NewPod from './new-pod';
import { setMapViewport } from '../../state/actions/map';
import * as turf from '@turf/turf';
import TokenManager from '../../service/tokens';
import myPodSVG from '../../assets/images/pods/pod.svg';
import inactivePodSVG from '../../assets/images/pods/inactive-legend.svg';
import myPod from '../../assets/images/pods/myPod.png';
import activePod from '../../assets/images/pods/activePod.png';
import inactivePod from '../../assets/images/pods/inactivePod.png';
import clusterPod from '../../assets/images/pods/clusterPod@1x.png';
import newPod from '../../assets/images/pods/newPod.png';
import Podup from './popup';
//import Input from '@material-ui/core/TextField';
import { Button, makeStyles, Snackbar, Typography } from '@material-ui/core';
import '@fortawesome/fontawesome-free';
import 'components-font-awesome';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
//import { ContactSupportOutlined } from '@material-ui/icons';
const Map = ReactMapboxGl({
  minZoom: 2,
  maxZoom: 14,
  center: [24.324129702844914, -15.764143772365884],
  zoom: 2,
  accessToken: process.env.REACT_APP_PODS_MAPBOX_TOKEN,
  renderWorldCopies: false,
  initialized: false,
});

const mapStyle = 'mapbox://styles/mapbox/basic-v9';

const useStyles = makeStyles((theme) => ({
  map: {
    flexGrow: 1,
    height: 'auto !important',
    // width: "auto !important",
  },
  clusterWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  cluster: {
    width: 50,
    display: 'inline',
  },
  clusterLabel: {
    color: 'white',
    position: 'absolute',
    fontSize: '12pt',
  },
  marker: {
    width: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  controlWrapper: {
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
    '& #zoomOut': {
      borderBottom: 'solid 1px rgba(0,0,0,.1)',
      borderBottomLeftRadius: 'unset !important',
      borderBottomRightRadius: 'unset !important',
    },
  },
  zoomControl: {
    border: 'unset !important',
    boxShadow: 'unset !important',
  },
  rotationControl: {
    borderTop: 'unset !important',
    boxShadow: 'unset !important',
    border: 'unset !important',
    zIndex: '9 !important',
    '& span': {
      width: 21,
      height: 21,
      // marginLeft: -7,
      marginTop: -2,
    },
  },
  joining: {
    position: 'absolute',
    // width: 70,
    left: 'calc(50% + 60px)',
    top: 20,
    zIndex: 5,
  },
  remoteButton: {
    padding: '10px 20px !important',
  },
}));

const paint = {
  'circle-radius': {
    base: 150.75,
    stops: [
      [12, 10],
      [22, 180],
    ],
  },
  'circle-color': '#00FFFF',
  'circle-opacity': 0.75,
};
// const layoutLayer = {
//   'icon-image': ['get', 'markerName'],
//   'icon-allow-overlap': true,
//   'icon-ignore-placement': true,
//   'icon-size': 0.15,
//   'icon-offset': [0, -100],
// };

// keep map from showing dead space
const bounds = [
  [-179.85719469993938, -85.03594172744246], // Southwest coordinates
  [181.06754144587745, 85.05112900000003], // Northeast coordinates
];

const labelLayer = {
  // get the title name from the source's "title" property
  'text-field': ['get', 'name'],
  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
  'text-offset': [0, 0.5],
  'text-anchor': 'top',
  'text-allow-overlap': true,
};

const POSITION_CIRCLE_PAINT = {
  'circle-stroke-width': 4,
  'circle-radius': 10,
  'circle-blur': 0.15,
  'circle-color': '#2c0040',
  'circle-stroke-color': 'white',
};

const MapView = (props) => {
  const { match } = props;
  const { t } = useTranslation();
  const isIframe = match.path === '/frame';
  const [store, dispatch] = useContext(StoreContext);
  const classes = useStyles();
  const [lngLat, setLngLat] = useState(null);
  const [rawData, setRawData] = useState([]);
  const [allPoints, setAllPoints] = useState([]);
  const [somePoints, setSomePoints] = useState([]);
  // const [zoom, setZoom] = useState(2);
  const viewport = useRef(store.map.viewport);
  // const [mapInitialized, setMapInitialized] = useState(false);
  // const isMobile = useMediaQuery({ query: '(max-width: 720px)' });
  // const isLargeScreen = useMediaQuery({ query: '(min-width: 1000px)' });
  const mapRef = useRef({ current: null });
  // const popRef = useRef({ current: null });
  const [myPods, setMyPods] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const [remotePods, setRemotePods] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState({});
  const [selectedPod, setSelectedPod] = useState({});
  const [isPopup, setPopup] = useState(false);
  const [countryCount, setCountryCount] = useState(0);
  const [podCount, setPodCount] = useState(0);
  const [memberCount, setMemberCount] = useState(0);
  const [adding, setAdding] = useState(false);
  // const [showForm, setShowForm] = useState(false);
  const [dragCoords, setDragCoords] = useState(null);

  const [snacking, setSnacking] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const updateTotals = useCallback((pods) => {
    //set
    let countries = Array.from(
      new Set(pods.map((d) => d.country).filter((a) => a))
    ).length;
    setCountryCount(formatNumber(countries));
    setPodCount(formatNumber(pods.length));
    let members = Array.from(
      new Set(
        pods
          .map((d) => d.podMembers)
          .flat()
          .filter((m) => m.status === 'approved')
          .map((d) => d.podProfileId)
      )
    ).length;
    setMemberCount(formatNumber(members));
  }, []);

  const clusterMarker = (coordinates, pointCount) => (
    <Marker
      key={coordinates.toString()}
      coordinates={coordinates}
      anchor="bottom"
      className={classes.clusterWrapper}
      onClick={() => setPopup(false)}
    >
      <img src={clusterPod} alt="cluster" className={classes.cluster} />
      <div className={classes.clusterLabel}>{pointCount}</div>
    </Marker>
  );

  const getAllPods = useCallback(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const CancelToken2 = axios.CancelToken;
    const source2 = CancelToken2.source();
    const tm = TokenManager.getInstance();
    tm.addToken({ id: 'my-pods', source });
    tm.addToken({ id: 'pods', source: source2 });

    axios
      .all([
        axios.post(
          `${rootUrl}/api/get-my-pods`,
          { isSpecific: true },
          { withCredentials: true, cancelToken: source.token }
        ),
        axios.get(`${rootUrl}/api/all-pods`, { cancelToken: source2.token }),
      ])
      .then(
        axios.spread((myPods, results) => {
          // adding extra logic to only show good spatial data
          const pods = results.data.filter(
            (p) =>
              !p.isRemote &&
              p.location &&
              p.location.coordinates.length === 2 &&
              !isNaN(parseFloat(p.location.coordinates[0])) &&
              !isNaN(parseFloat(p.location.coordinates[1]))
          );
          setDataReady(true);
          setRemotePods(
            results.data.filter((p) => p.isRemote).map((p) => p.id)
          );
          // console.log('calling for all pods: ', pods);
          setRawData(results.data);
          const mine = myPods.data.map((p) => p.id) || [];
          setMyPods(mine);
          const geojson = pods.map((pod) => {
            return {
              type: 'Feature',
              geometry: pod.location,
              properties: {
                id: pod.id,
                isNew: pod.isNew,
                isRemote: pod.isRemote,
                name: pod.name,
                description: pod.description,
                country: pod.country,
                state: pod.state,
                city: pod.city,
                facebook: pod.facebook,
                instagram: pod.instagram,
                twitter: pod.twitter,
                website: pod.website,
                markerName: getMarkerName(pod, mine),
              },
            };
          });

          updateTotals(pods);

          setAllPoints({
            type: 'FeatureCollection',
            features: geojson.flat(),
          });

          setSnacking(false);
        })
      );
  }, [updateTotals]);

  useEffect(() => {
    getAllPods();
  }, [getAllPods]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnacking(false);
  };

  const getMarkerName = (pod, mine) => {
    return mine?.some((p) => p === pod.id)
      ? 'my-pod'
      : pod.podCoordinators.length
      ? 'active-pod'
      : 'inactive-pod';
  };

  const updateMyPods = (podId, change, skipUpdate) => {
    const model = allPoints.features.find((p) => p.properties.id === podId);
    const rawCopy = [...rawData];
    const raw = rawCopy.find((p) => p.id === podId);
    const pods = [...myPods];
    if (change === '+') {
      pods.push(podId);
      //update marker
      if (model) {
        model.properties.markerName = 'my-pod';
      }
    } else if (change === '-') {
      pods.splice(pods.indexOf(podId), 1);
      //update marker
      if (model) {
        model.properties.markerName = getMarkerName(raw, pods);
      }
    }

    setAllPoints(allPoints);
    setMyPods(pods);
    //layer source does not like to update automatically, giving it some extra attention
    mapRef.current &&
      mapRef.current.getSource &&
      mapRef.current.getSource('pods').setData(allPoints);

    if (!skipUpdate && model) {
      // update raw data too for this one pod so we know who is still a member
      axios
        .get(`${rootUrl}/api/pods/${model.properties.id}`, {
          withCredentials: true,
        })
        .then((results) => {
          if (results.status === 200) {
            //success
            raw.podMembers = results.data.podMembers;
            updateTotals(rawCopy);
            setRawData(rawCopy);
          }
        });
    }
  };

  const formatNumber = (num, digits = 0) => {
    return Number(parseFloat(num).toFixed(0)).toLocaleString('en', {
      minimumFractionDigits: digits,
    });
  };

  const onViewportChange = (updatedViewport) => {
    // console.log('map has moved');
    setPopup(false);
    // TODO Save this in the store, but only load it on initial map load to prevent unneccessary rerenders
    if (!mapRef.current.initialized) {
      viewport.current = {
        longitude: updatedViewport.getCenter().lng,
        latitude: updatedViewport.getCenter().lat,
        zoom: updatedViewport.getZoom(),
      };
      setMapViewport(dispatch, viewport.current);
    }
  };

  const clickLocation = (e, feature) => {
    setSelectedFeature(feature);
    //lookup pod against main data source
    const selectedPod = rawData.find((r) => r.id === feature.properties.id);
    setSelectedPod(selectedPod);
    // console.log('popups location:', e.point);
    // console.log('clicked a feature: ', feature);
    // console.log('has a pod: ', selectedPod);
    // let offset = [100, 0];
    // if (isMobile) {
    //   offset = [0, 0];
    // } else if (isLargeScreen) {
    //   offset = [150, 0];
    // }
    mapRef.current.easeTo({
      center: [
        feature.geometry.coordinates[0],
        feature.geometry.coordinates[1],
      ],
      //offset,
      //zoom: mapRef?.current.getZoom() || null,
    });
    // reset for new popup
    if (selectedPod) {
      setPopup(true);
    }
  };

  const addImages = (map) => {
    map.loadImage(activePod, (error, image) => {
      if (error) throw error;
      map.addImage('active-pod', image);
    });

    map.loadImage(myPod, (error, image) => {
      if (error) throw error;
      map.addImage('my-pod', image);
    });

    map.loadImage(inactivePod, (error, image) => {
      if (error) throw error;
      map.addImage('inactive-pod', image);
    });

    map.loadImage(newPod, (error, image) => {
      if (error) throw error;
      map.addImage('new-pod', image);
    });

    map.loadImage(clusterPod, (error, image) => {
      if (error) throw error;
      map.addImage('cluster-pod', image);
    });
  };

  const searchSelected = (item, text) => {
    try {
      const lower = item?.text?.toLowerCase() || text?.toLowerCase();
      const map = mapRef?.current;
      if (item) {
        // do a spatial search to find all points within X distance
        // set bbox as 5px reactangle area around clicked point
        mapRef.current.flyTo({
          center: item.geometry.coordinates,
          zoom: map?.getZoom() > 6 ? map.getZoom() : 6,
        });

        const buffered = turf.buffer(item, 200, { units: 'miles' });
        // filter out any points that don't have geometry (remote)
        console.log(allPoints);
        const features = turf
          .pointsWithinPolygon(allPoints, buffered)
          .features.sort((a, b) =>
            a.properties.name < b.properties.name ? -1 : 1
          );

        //also add in any features that have a matching key field
        allPoints.features
          .filter(
            (f) =>
              f.properties.country?.toLowerCase() === lower ||
              f.properties.state?.toLowerCase() === lower ||
              f.properties.city?.toLowerCase() === lower
          )
          .forEach((feature) => {
            if (
              !features.some(
                (feat) => feat.properties.id === feature.properties.id
              )
            ) {
              features.push(feature);
            }
          });

        const orderedFeatures = [];
        // extra logic to get the desired result at the top of the list
        features.forEach((f) => {
          if (f.properties.name.toLowerCase().includes(text.toLowerCase())) {
            orderedFeatures.unshift(f);
          } else {
            orderedFeatures.push(f);
          }
        });

        // Run through the selected features and set a filter
        // to match features with unique FIPS codes to activate
        // the `counties-highlighted` layer.
        const filter = orderedFeatures.reduce(
          function (memo, feature) {
            memo.push(feature.properties.id);
            return memo;
          },
          ['in', 'id']
        );

        map.setFilter('pods-highlighted', filter);

        if (!orderedFeatures.length) {
          orderedFeatures.push({
            empty: true,
            properties: { name: 'No results' },
          });
        }
        setSomePoints(orderedFeatures);
      } else {
        setSomePoints([]);
        map &&
          map.setFilter &&
          map.setFilter('pods-highlighted', ['in', 'id', '']);
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const mapMove = (e, p) => {
    setLngLat(p.lngLat);
  };

  const clickRemote = (e) => {
    //lookup pod against main data source
    const remotePod = rawData.find((r) => r.isRemote);
    setSelectedPod(remotePod);

    // reset for new popup
    if (remotePod) {
      // fake a location
      const remoteFeature = {
        geometry: {
          coordinates: [lngLat.lng, lngLat.lat],
          type: 'Point',
        },
        properties: {
          description: remotePod.description,
          facebook: remotePod.facebook,
          id: remotePod.id,
          instagram: remotePod.instagram,
          twitter: remotePod.twitter,
          website: remotePod.website,
          isNew: false,
          isRemote: true,
          name: 'Remote',
        },
        type: 'Feature',
      };
      setSelectedFeature(remoteFeature);
      setPopup(true);
    }
  };

  const joinRemote = () => {
    // setting ourselves up to support muliple "remote" pods, but really only supporting 1 for now
    axios
      .post(
        `${rootUrl}/api/pods/join/remote`,
        { id: remotePods[0] },
        { withCredentials: true }
      )
      .then((results) => {
        if (results.status === 200) {
          setMessage(
            'Remote pod joined You will be notified when your account is approved'
          );
          setSeverity('success');
          setSnacking(true);
          updateMyPods(remotePods[0], '+', true);
        }
      });
  };

  const addPod = (item, text) => {
    if (dragCoords) {
      //create new pod - service call here
      setDragCoords(null);
      setAdding(null);
    }
  };

  const dragEnd = (point) => {
    setDragCoords(point.lngLat);
  };

  // const dragging = (point) => {
  //   //setDragLocation(point.lngLat);
  // };

  const mapClick = (e, point) => {
    if (adding) {
      //add marker to map and allow user to drag around
      setDragCoords(point.lngLat);
      if (mapRef.current.getZoom() < 5.5) {
        mapRef.current.flyTo({ center: point.lngLat, zoom: 6 });
        //setZoom(6);
      }
    }
  };

  // const zoomEnd = (e, point) => {
  //   // const podLayer = e.getLayer("pods");
  //   // // show hide labels on map based on zoom
  //   // podLayer && podLayer.setLayoutProperty("text-field", e.getZoom() > 5 ? ['get', 'name'] : '');
  //   setZoom(e.getZoom());
  // };

  const getMarker = (feature) => {
    switch (feature.properties.markerName) {
      case 'my-pod': {
        return <img src={myPod} alt="my pod" className={classes.marker} />;
      }
      case 'active-pod': {
        return (
          <img src={activePod} alt="active pod" className={classes.marker} />
        );
      }
      case 'inactive-pod': {
        return (
          <img
            src={inactivePod}
            alt="inactive pod"
            className={classes.marker}
          />
        );
      }
      default:
        return null;
    }
  };

  let data = allPoints;

  return (
    <>
      <Map
        ref={mapRef}
        // https://github.com/alex3165/react-mapbox-gl/issues/785
        style={mapStyle} // eslint-disable-line
        onClick={mapClick}
        // onZoomEnd={zoomEnd}
        onMouseMove={mapMove}
        className={classes.map}
        maxBounds={bounds}
        // center={[-10.480557797166625, 39.390857617039984]}
        //zoom={[zoom]} //causes all sort of map bouncing on zooms
        onStyleLoad={(map) => {
          mapRef.current = map;
          map.setZoom(2);
          mapRef.current.initialized = true;
          map.resize();
          // add images
          addImages(map);

          setTimeout(() => {
            map.resize();
            // seemed to need a second to get full width
          }, 1000);
          // map.jumpTo({
          //   center: [-89.62, 35.68],
          //   zoom: 4,
          // });
          // map.jumpTo({
          //   center: [-10.480557797166625, 39.390857617039984],
          //   zoom: 2,
          // });
          mapRef.current.onMoveEnd = (viewport) => onViewportChange(viewport);
        }}
        renderChildrenInPortal
        containerStyle={{
          height: '100%',
          width: '100%',
          display: 'flex',
          backgroundColor: '#75cef0',
        }}
      >
        <Podup
          feature={selectedFeature}
          pod={selectedPod}
          coordinators={selectedPod?.podCoordinators}
          myPods={myPods}
          isIframe={isIframe}
          updateMap={updateMyPods}
          setSnacking={setSnacking}
          setMessage={setMessage}
          setSeverity={setSeverity}
          isPopup={isPopup}
          setPopup={setPopup}
          joinRemote={joinRemote}
          remotePods={remotePods}
        />
        <div className={styles.stats}>
          <div className={styles.left}>
            {t('COUNTRIES')} <div className={styles.stat}>{countryCount}</div>
          </div>
          <div className={styles.middle}>
            {t('PODS')} <div className={styles.stat}>{podCount}</div>
          </div>
          <div className={styles.right}>
            {t('MEMBERS')} <div className={styles.stat}>{memberCount}</div>
          </div>
        </div>

        <div className={`${styles.desktopOnly} ${classes.controlWrapper}`}>
          <ZoomControl className={classes.zoomControl} />
          <RotationControl className={classes.rotationControl} />
        </div>

        {/* somewhat suddenly, the markers only show on the map sometimes. 
            Updated logic to only add the layers once data has returned. Seems to help  */}
        {data?.features && (
          <Source
            id="pods"
            geoJsonSource={{
              type: 'geojson',
              data: data,
              cluster: true,
              clusterMaxZoom: 14, // Max zoom to cluster points on
              clusterRadius: 50,
            }}
          />
        )}

        {data?.features && (
          <Layer
            type="circle"
            id="pods-highlighted"
            sourceId="pods"
            layout={{}}
            paint={paint}
            filter={['in', 'id', '']}
          />
        )}

        {data?.features && (
          <Layer
            type="symbol"
            id="pods-labels"
            sourceId="pods"
            layout={labelLayer}
            onClick={(e) => clickLocation(e, e.features[0])}
            minZoom={5.5}
          />
        )}

        {data?.features && (
          <Cluster
            id="cluster-pods"
            ClusterMarkerFactory={clusterMarker}
            zoomOnClick={true}
            maxZoom={14}
            radius={60}
          >
            {data.features.map((feature, key) => (
              <Marker
                key={key}
                anchor="bottom"
                coordinates={feature.geometry.coordinates}
                onClick={(e) => clickLocation(e, feature)}
                onMouseEnter={() => {
                  mapRef.current.getCanvas().style.cursor = 'pointer';
                }}
                onMouseLeave={() => {
                  mapRef.current.getCanvas().style.cursor = '';
                }}
              >
                {getMarker(feature)}
              </Marker>
            ))}
          </Cluster>
        )}

        <Layer id="dragMarker" type="circle" paint={POSITION_CIRCLE_PAINT}>
          {dragCoords && (
            <Feature
              key={'draggable'}
              coordinates={dragCoords.toArray()}
              draggable={true}
              // onDrag={dragging}
              onDragEnd={dragEnd}
            />
          )}
        </Layer>
      </Map>
      {!isIframe && (
        <div className={styles.leftpanel}>
          <LeftPanel
            searchEvent={searchSelected}
            addEvent={addPod}
            map={mapRef}
            features={somePoints}
            adding={adding}
            setAdding={setAdding}
            dragCoords={dragCoords}
            setDragCoords={setDragCoords}
            rawData={rawData}
            dataReady={dataReady}
            myPods={myPods}
          />
        </div>
      )}
      {!isIframe && dataReady && (
        <div className={classes.joining}>
          <Button
            className={`${styles.purpleButton} ${classes.remoteButton}`}
            onClick={clickRemote}
          >
            {t('Remote Pod')}
          </Button>
          {/* <ClickAwayListener onClickAway={handleClickAway}>
            <Popper
              id="simple-popper"
              open={open}
              anchorEl={anchorEl}
              className={classes.popper}
              placement="bottom-start"
            >
              <List component="nav">
                {searchResults.map((f) => (
                  <ListItem button id={f.id} key={f.id} onClick={resultClicked}>
                    <ListItemIcon>
                      <i className="fas fa-map-marker-alt" />
                    </ListItemIcon>
                    <ListItemText primary={f.place_name} />
                  </ListItem>
                ))}
              </List>
            </Popper>
          </ClickAwayListener> */}
        </div>
      )}
      {dragCoords && (
        <div className={styles.newpod}>
          <NewPod
            location={dragCoords}
            setLocation={setDragCoords}
            setSnacking={setSnacking}
            setMessage={setMessage}
            setSeverity={setSeverity}
            setAdding={setAdding}
            getData={getAllPods}
          />
        </div>
      )}

      <div className={styles.legend}>
        <div className={`${styles.legendItem} ${styles.legendItemTop}`}>
          <img src={inactivePodSVG} alt="my pod" className={styles.inactive} />
          <Typography variant="body1" className={styles.legendText}>
            {t('inactive pods')}
          </Typography>
        </div>
        <div className={`${styles.legendItem}`}>
          <i className={`fa fa-star ${styles.pod}`} aria-hidden="true"></i>
          <Typography variant="body1" className={styles.legendText}>
            {t('active pods')}
          </Typography>
        </div>
        <div className={styles.legendItem}>
          <img src={myPodSVG} alt="my pod" className={styles.myPod} />
          <Typography variant="body1" className={styles.legendText}>
            {t('my pods')}
          </Typography>
        </div>
      </div>

      <Snackbar
        open={snacking}
        autoHideDuration={6000}
        // anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          <AlertTitle>
            {t(
              severity[0].toUpperCase() +
                severity.substr(1, severity.length - 1)
            )}
          </AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MapView;
