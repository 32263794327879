import React, { useContext, useEffect, Suspense, lazy } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter, Route } from 'react-router-dom';
import axios from 'axios';
import Store from './state/store';
import StoreContext from './state/context/store';
import rootReducer from './state/reducers';
import Landing from './pages/landing';
import Footer from './components/Gage/Footer';
import Header from './components/Gage/Header';
import CookieBanner from './components/CookieBanner';
import About from './pages/about';
// Add AddCircle dependency to top bundle to avoid dependency ordering mismatch
// eslint-disable-next-line no-unused-vars
import AddCircle from './components/AddCircle';
import Media from './pages/media';
import Search from './pages/search';
// Add Select dependency to top bundle to avoid dependency ordering mismatch
// eslint-disable-next-line no-unused-vars
import Select from './components/Select';
import { useCookie } from './lib/useCookie';
import { setMe } from './state/actions/me';
import { rootUrl, application } from './config';
import './App.scss';
import { CircularProgress } from '@material-ui/core';
import Unsubscribe from './pages/unsubscribe';
import { SnackContextProvider } from './state/context/snack';
import { LayoutContextProvider } from './state/context/layout';
import { ListContextProvider } from './state/context/lists';

const Profile = lazy(() =>
  import(
    /* webpackChunkName: "app" */
    /* webpackPrefetch: true */
    './pages/profile'
  )
);
const SignUp = lazy(() =>
  import(
    /* webpackChunkName: "signup" */
    /* webpackPrefetch: true */
    './pages/registration/sign-up'
  )
);
const Expertise = lazy(() =>
  import(
    /* webpackChunkName: "signup" */
    /* webpackPrefetch: true */
    './pages/registration/expertise'
  )
);

const Identity = lazy(() =>
  import(
    /* webpackChunkName: "signup" */
    /* webpackPrefetch: true */
    './pages/registration/diversity'
  )
);

const Work = lazy(() =>
  import(
    /* webpackChunkName: "signup" */
    /* webpackPrefetch: true */
    './pages/registration/work'
  )
);
const Contact = lazy(() =>
  import(
    /* webpackChunkName: "signup" */
    /* webpackPrefetch: true */
    './pages/registration/contact'
  )
);
const Welcome = lazy(() =>
  import(
    /* webpackChunkName: "signup" */
    /* webpackPrefetch: true */
    './pages/registration/welcome'
  )
);
const Login = lazy(() =>
  import(
    /* webpackChunkName: "login" */
    /* webpackPrefetch: true */
    './pages/registration/login'
  )
);
const TermsOfService = lazy(() =>
  import(
    /* webpackChunkName: "signup" */
    /* webpackPrefetch: true */
    './pages/terms-of-service'
  )
);
const Feedback = lazy(() =>
  import(
    /* webpackChunkName: "notices" */
    /* webpackPrefetch: true */
    './pages/feedback'
  )
);
const Privacy = lazy(() =>
  import(
    /* webpackChunkName: "notices" */
    './pages/privacy'
  )
);
const ReleaseNotes = lazy(() =>
  import(
    /* webpackChunkName: "notices" */
    './pages/release-notes'
  )
);
const CookieNotice = lazy(() =>
  import(
    /* webpackChunkName: "notices" */
    './pages/cookie-notice'
  )
);

const ForgotPassword = lazy(() =>
  import(
    /* webpackChunkName: "passwords" */
    './pages/registration/forgot-password'
  )
);
const ResetPassword = lazy(() =>
  import(
    /* webpackChunkName: "passwords" */
    './pages/registration/reset-password'
  )
);
const Admin = lazy(() =>
  import(
    /* webpackChunkName: "admin" */
    './pages/admin'
  )
);

const Authenticator = (props) => {
  const [, dispatch] = useContext(StoreContext);
  useEffect(() => {
    axios
      .get(`${rootUrl}/api/gage/me`, { withCredentials: true })
      .then((me) => {
        setMe(dispatch, me.data);
        if (me.data.id) {
          axios
            .get(`${rootUrl}/api/gageProfile/${me.data.id}`, {
              withCredentials: true,
            })
            .then((woman) => {
              if (woman.status === 200) {
                setMe(dispatch, woman.data);
              }
            })
            .catch((error) => {
              if (error) {
                console.log('ERROR', error);
              }
            });
        }
      })
      .catch((error) => {
        console.log('Error on api/gage/me: ', error);
      });
  }, [dispatch]);
  return null;
};

const Tracker = (props) => {
  const [, dispatch] = useContext(StoreContext);
  const [cookiesAccepted] = useCookie('cookiesAccepted', false);
  const [analyticsAccepted] = useCookie('gAnalyticsAccepted', true);

  useEffect(() => {
    if (cookiesAccepted && analyticsAccepted) {
      setMe(dispatch, { tracking: true });
    }
  }, [analyticsAccepted, cookiesAccepted, dispatch]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [props.history.location.pathname]);
  return null;
};

function App(props) {
  const [cookiesAccepted, setCookiesAccepted] = useCookie(
    'cookiesAccepted',
    false
  );
  const [analyticsAccepted, setAnalyticsAccepted] = useCookie(
    'gAnalyticsAccepted',
    true
  );

  useEffect(() => {
    console.log('Application config set to: ', application);
    console.log(
      'Environment variable set to: ',
      process.env.REACT_APP_APPLICATION
    );
    if (cookiesAccepted && analyticsAccepted) {
      ReactGA.initialize(process.env.REACT_APP_GAGE_ANALYTICS_ID, {
        debug: process.env.NODE_ENV !== 'production',
        gaOptions: {
          siteSpeedSampleRate: 100,
          allowAdFeatures: false,
        },
      });
    }
  }, [cookiesAccepted, analyticsAccepted]);

  return (
    <div className="App">
      <LayoutContextProvider>
        <SnackContextProvider>
          <Store rootReducer={rootReducer}>
            <Authenticator />
            <BrowserRouter>
              <Suspense
                fallback={
                  <CircularProgress
                    style={{
                      color: '#2c0040',
                      marginLeft: '20px',
                      marginTop: '20px',
                      width: '50px',
                      height: '50px',
                    }}
                  />
                }
              >
                <ListContextProvider>
                  <Route path="/" component={Tracker} />
                  <Header />
                  <Route exact path="/" component={Landing} />
                  <Route path="/about" component={About} />
                  <Route path="/media" component={Media} />
                  <Route path="/search" component={Search} />
                  <Route path="/create-account" component={SignUp} />
                  <Route path="/sign-up" component={SignUp} />
                  <Route path="/login" component={Login} />
                  <Route path="/expertise" component={Expertise} />
                  <Route path="/work" component={Work} />
                  <Route path="/contact" component={Contact} />
                  <Route path="/welcome" component={Welcome} />
                  <Route path="/profile/:id" component={Profile} />
                  <Route path="/identity" component={Identity} />
                  <Route path="/feedback" component={Feedback} />
                  <Route path="/privacy" component={Privacy} />
                  <Route path="/terms-of-service" component={TermsOfService} />
                  <Route path="/cookie-notice" component={CookieNotice} />
                  <Route path="/forgot-password" component={ForgotPassword} />
                  <Route path="/reset-password" component={ResetPassword} />
                  <Route path="/admin" component={Admin} />
                  <Route path="/unsubscribe" component={Unsubscribe} />
                  <Route path="/release-notes" component={ReleaseNotes} />

                  {!cookiesAccepted && (
                    <CookieBanner
                      setCookiesAccepted={setCookiesAccepted}
                      setAnalyticsAccepted={setAnalyticsAccepted}
                      analyticsAccepted={analyticsAccepted}
                    />
                  )}
                  <Footer />
                </ListContextProvider>
              </Suspense>
            </BrowserRouter>
          </Store>
        </SnackContextProvider>
      </LayoutContextProvider>
    </div>
  );
}

export default App;
