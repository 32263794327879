import React, { useState, useMemo, useEffect } from 'react';
import styles from './index.module.scss';
// import { useTranslation } from 'react-i18next';
import StoreContext from '../../state/context/store';
import ArrowUp from '../../assets/images/gap/arrow-up.svg';
import ArrowDown from '../../assets/images/gap/arrow-down.svg';
import ArrowMiddle from '../../assets/images/gap/arrow-middle.svg';
import PageLeftArrow from '../../assets/images/gap/page-left-arrow.svg';
import PageRightArrow from '../../assets/images/gap/page-right-arrow.svg';
import PageEllipses from '../../assets/images/gap/page-ellipses.svg';
import { Select, MenuItem } from '@material-ui/core';

const GapTable = (props) => {
  // const { t } = useTranslation();
  // const [context, dispatch] = useContext(StoreContext);
  // const { me } = context;
  const data = props.data;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [sort, setSort] = useState({ order: 'asc', orderBy: 'id' });
  const count = data.length;
  const [totalPages, setTotalPages] = useState(Math.ceil(count / rowsPerPage));
  const sortedRows = useMemo(() => sortRows(data, sort), [data, sort]);
  const calculatedRows = paginateRows(sortedRows, activePage, rowsPerPage);

  const columns = [
    { accessor: 'id', label: '', width: 'auto' },
    { accessor: 'talkType', label: 'Type', width: 145 },
    {
      accessor: 'honorariaValue',
      label: 'Honoraria value (hourly, USD)',
      width: 150,
    },
    { accessor: 'year', label: 'Year', width: 80 },
    { accessor: 'countryOfSpeakingEngagement', label: 'Country', width: 150 },
    {
      accessor: 'sectorOfSpeakingEngagement',
      label: 'Organization Type',
      width: 315,
    },

    { accessor: 'currentPosition', label: 'Respondent Position', width: 315 },
    {
      accessor: 'methodToDetermineFee',
      label: 'How was the honoraria determined?',
      width: 315,
    },
    // { accessor: '', label: 'Did you receive additional compensation?' },
    // { accessor: '', label: '' },
    // { accessor: '', label: '' },
    // { accessor: '', label: '' },
    // { accessor: '', label: '' },
    // { accessor: '', label: '' },
    // { accessor: '', label: '' },
    // { accessor: '', label: '' },
    // { accessor: '', label: '' },
  ];

  function isEmpty(obj = {}) {
    return Object.keys(obj).length === 0;
  }

  function isString(value) {
    return typeof value === 'string' || value instanceof String;
  }

  function isNumber(value) {
    return typeof value == 'number' && !isNaN(value);
  }

  function isBoolean(value) {
    return value === true || value === false;
  }

  function isNil(value) {
    return typeof value === 'undefined' || value === null;
  }

  function isDateString(value) {
    if (!isString(value)) return false;

    return value.match(/^\d{2}-\d{2}-\d{4}$/);
  }

  function convertDateString(value) {
    return value.substr(6, 4) + value.substr(3, 2) + value.substr(0, 2);
  }

  function toLower(value) {
    if (isString(value)) {
      return value.toLowerCase();
    }
    return value;
  }

  function convertType(value) {
    if (isNumber(value)) {
      return value.toString();
    }

    if (isDateString(value)) {
      return convertDateString(value);
    }

    if (isBoolean(value)) {
      return value ? '1' : '-1';
    }

    return value;
  }

  function sortRows(rows, sort) {
    return rows.sort((a, b) => {
      const { order, orderBy } = sort;

      if (isNil(a[orderBy])) return 1;
      if (isNil(b[orderBy])) return -1;

      const aLocale = convertType(a[orderBy]);
      const bLocale = convertType(b[orderBy]);

      if (order === 'asc') {
        return aLocale.localeCompare(bLocale, 'en', {
          numeric: isNumber(b[orderBy]),
        });
      } else {
        return bLocale.localeCompare(aLocale, 'en', {
          numeric: isNumber(a[orderBy]),
        });
      }
    });
  }

  function paginateRows(sortedRows, activePage, rowsPerPage) {
    return [...sortedRows].slice(
      (activePage - 1) * rowsPerPage,
      activePage * rowsPerPage
    );
  }

  const handleSort = (accessor) => {
    setActivePage(1);
    setSort((prevSort) => ({
      order:
        prevSort.order === 'asc' && prevSort.orderBy === accessor
          ? 'desc'
          : 'asc',
      orderBy: accessor,
    }));
  };

  const Table = ({ columns, rows }) => {
    return (
      <table className={styles.gapTable}>
        <thead>
          <tr>
            <th colSpan={6}>Speaking Engagement Characteristics</th>
            <th colSpan={2}>Honoraria Recipient Characteristics</th>
          </tr>
          <tr>
            {columns.map((column, i) => {
              const sortIcon = () => {
                if (i === 0) {
                  return '';
                }
                if (column.accessor === sort.orderBy) {
                  if (sort.order === 'asc') {
                    return <img src={ArrowUp} alt="arrow up" />;
                  }
                  return <img src={ArrowDown} alt="arrow down" />;
                } else {
                  return <img src={ArrowMiddle} alt="arrow middle" />;
                }
              };

              return (
                <th
                  style={{ minWidth: column.width }}
                  key={column.accessor}
                  onClick={() => handleSort(column.accessor)}
                >
                  <span>{column.label}</span>
                  {sortIcon()}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {calculatedRows.map((row) => {
            return (
              <tr key={row.id}>
                {columns.map((column) => {
                  if (column.format) {
                    return (
                      <td key={column.accessor}>
                        {column.format(row[column.accessor])}
                      </td>
                    );
                  }
                  return (
                    <td
                      key={column.accessor}
                      className={`${styles[column.accessor]}`}
                    >
                      {row[column.accessor]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const getIndex = (rowsPerPage, activePage) => {
    return rowsPerPage * activePage;
  };

  const handleRowsShown = (event) => {
    let newTotalPages = Math.ceil(count / event.target.value);
    let newActivePage = Math.floor(
      getIndex(rowsPerPage, activePage) / event.target.value
    );
    if (newActivePage <= 1) {
      newActivePage = 1;
    } else if (newActivePage > newTotalPages) {
      newActivePage = newTotalPages;
    }
    setRowsPerPage(event.target.value);
    setTotalPages(newTotalPages);
    setActivePage(newActivePage);
  };

  useEffect(() => {
    let event = {
      target: {
        value: rowsPerPage,
      },
    };
    handleRowsShown(event);
  }, [data]);

  const SelectRows = () => {
    // const CustomMenuItem = styled(MenuItem)({
    //   '&::before': {
    //     'border-bottom-width': 0
    //     // backgroundColor: '#6E3694',
    //   },
    // });

    return (
      <Select
        labelId="show-rows-label"
        id="show-rows-select"
        value={rowsPerPage}
        label="Number of rows to show"
        onChange={handleRowsShown}
        className={styles.rowsShown}
        // variant='outlined'
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
    );
  };

  const Pagination = ({
    activePage,
    count,
    rowsPerPage,
    totalPages,
    setActivePage,
  }) => {
    const beginning = activePage === 1 ? 1 : rowsPerPage * (activePage - 1) + 1;
    const end = activePage === totalPages ? count : beginning + rowsPerPage - 1;

    return (
      <div className={styles.pageControl}>
        <div className={styles.pageLabel}>
          {/* Showing {beginning === end ? end : `${beginning} - ${end}`} of {count} entries */}
          Showing <SelectRows /> of {count} entries
        </div>
        <div className={styles.pagination}>
          <button
            disabled={activePage === 1}
            onClick={() => setActivePage(activePage - 1)}
          >
            <img src={PageLeftArrow} alt="page left" />
          </button>
          <button
            className={activePage === 1 ? styles.active : styles.notActive}
            onClick={() => setActivePage(1)}
          >
            1
          </button>
          {activePage - 2 > 1 && <img src={PageEllipses} alt="page break" />}
          {activePage - 1 > 1 && (
            <button onClick={() => setActivePage(activePage - 1)}>
              {activePage - 1}
            </button>
          )}
          {activePage !== 1 && activePage !== totalPages && (
            <button
              className={styles.active}
              onClick={() => setActivePage(activePage)}
            >
              {activePage}
            </button>
          )}
          {activePage + 1 < totalPages && (
            <button onClick={() => setActivePage(activePage + 1)}>
              {activePage + 1}
            </button>
          )}
          {activePage + 2 < totalPages && (
            <img src={PageEllipses} alt="page break" />
          )}
          {totalPages !== 0 && (
            <button
              className={
                activePage === totalPages ? styles.active : styles.notActive
              }
              onClick={() => setActivePage(totalPages)}
            >
              {totalPages}
            </button>
          )}
          <button
            disabled={activePage === totalPages}
            onClick={() => setActivePage(activePage + 1)}
          >
            <img src={PageRightArrow} alt="page right" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.tableContainer}>
        <Table rows={data} columns={columns} />
      </div>
      <Pagination
        activePage={activePage}
        count={count}
        rowsPerPage={rowsPerPage}
        totalPages={totalPages}
        setActivePage={setActivePage}
      />
    </>
  );
};

export default GapTable;
