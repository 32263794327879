import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import styles from './index.module.scss';
import '@fortawesome/fontawesome-free';
import 'components-font-awesome';
import { rootUrl } from '../../config';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {
  Box,
  Container,
  Link,
  CircularProgress,
  Snackbar,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import StoreContext from '../../state/context/store';

// Editable components
import podImage from '../../assets/images/pods/whalePod.svg';
import { Alert, AlertTitle } from '@material-ui/lab';
import PodDetails from './details';
import PodCoordinators from './coordinators';
import PodMembers from './members';
import PodSelection from './selection';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'absolute',
    top: 85,
    bottom: 0,
    overflow: 'auto',
    right: 0,
    left: 260,
    paddingRight: 15,
  },
  container: {
    marginLeft: 15,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  table: {
    minWidth: 650,
  },
  memberGrid: {
    maxHeight: 500,
  },
  selectWrapper: {
    width: '100%',
    textAlign: 'left',
  },
}));

const PodForm = (props) => {
  const { match, history } = props;
  const location = useLocation();
  const { t } = useTranslation();
  const classes = useStyles();
  const [store] = useContext(StoreContext);
  const { me } = store;

  const [myPods, setMyPods] = useState([]);
  const [pod, setPod] = useState({
    podCoordinators: [],
    podMembers: [],
    location: { coordinates: [] },
  });
  const [loading, setLoading] = useState(true);

  const [canEdit, setCanEdit] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isCoordinator, setIsCoordinator] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [members, setMembers] = useState([]);
  const [selectedPod, setSelectedPod] = useState(parseInt(match.params.id, 10));

  const [snacking, setSnacking] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [leaveOpen, setLeaveOpen] = useState(false);
  const [editing, setEditing] = useState(false);

  const [payload, setPayload] = useState(null);
  const [coordinators, setCoordinators] = useState([]);
  // I am getting a little cooky here
  const [membersToDelete, setMembersToDelete] = useState([]);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .all([
        axios.post(`${rootUrl}/api/get-my-pods`, {}, { withCredentials: true }),
        axios.get(
          `${rootUrl}/api/pods/${selectedPod}`,
          {},
          { withCredentials: true }
        ),
      ])
      .then(
        axios.spread((...responses) => {
          const mine = responses[0]?.data;
          setMyPods(mine);
          if (responses[1]?.data) {
            setPod(responses[1]?.data);
          }

          // if we don't have an active pod id, but the member has pods, set the first as active
          if (!selectedPod && mine.length) {
            history.push('/pods/' + mine[0].id);
          }

          setLoading(false);
        })
      )
      .catch((ex) => {
        setSnacking(true);
        setMessage(
          'That Pod ID does not exist. Try selecting from the menu on the left.'
        );
        setSeverity('error');
      });
  }, [selectedPod, history]);

  //changing the active pod via the url history does not trigger a refresh, this fixes it.
  useEffect(() => {
    const paths = location.pathname.split('/');
    const path = parseInt(paths[paths.length - 1]);
    if (selectedPod !== path) {
      setSelectedPod(path);
    }
  }, [location.pathname, selectedPod]);

  useEffect(() => {
    setCanEdit(false);
    // straight away, admin has full privileges
    if (me.isAdmin) {
      setCanEdit(true);
      setIsAdmin(true);
      return;
    }
    const pod = myPods.find((p) => p.id === selectedPod);
    if (pod) {
      // allow edit if user coordinator?
      if (pod.podCoordinators.some((c) => c.podProfile.userId === me.id)) {
        setCanEdit(true);
        setIsCoordinator(true);
        return;
      }
      // show a special message if user is not a member of this pod
      setIsMember(
        pod.podMembers.some(
          (p) => p.podProfile.userId === me.id && p.status === 'approved'
        ) || pod.podCoordinators.some((c) => c.podProfile.userId === me.id)
      );
      setIsPending(
        pod.podMembers.some(
          (p) =>
            p.podProfile.userId === me.id &&
            (!p.status || p.status === 'pending')
        )
      );
    }
  }, [me, myPods, canEdit, selectedPod]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnacking(false);
  };

  const saveChanges = (text) => {
    const saves = [];

    // look for any coordinator roles that have changed
    const coordinatorRoles = coordinators.filter((c) => {
      const foundPod = pod.podCoordinators.some(
        (p) => p.podProfileId === c.podProfileId && p.role !== c.role
      );
      return !c.isDelete && foundPod;
    });

    if (coordinatorRoles.length) {
      saves.push(
        axios.post(
          `${rootUrl}/api/coordinators/update`,
          { podId: pod.id, coordinators: coordinatorRoles },
          { withCredentials: true }
        )
      );
    }

    // look for any coordinators that have been removed
    if (coordinators.filter((c) => c.isDelete).length) {
      saves.push(
        axios.post(
          `${rootUrl}/api/coordinators/demote`,
          {
            podId: pod.id,
            coordinators: coordinators.filter((c) => c.isDelete),
          },
          { withCredentials: true }
        )
      );
    }

    //see of any members have a status change
    //and save only the changes
    const memberRoles = statuses.filter((s) => {
      const foundPod = members.some(
        (m) =>
          s.id === m.podProfileId &&
          s.status !== m.status &&
          s.status !== 'coordinator'
      );
      return foundPod;
    });

    if (memberRoles.length) {
      saves.push(
        axios.post(
          `${rootUrl}/api/members/update`,
          { podId: pod.id, members: memberRoles },
          { withCredentials: true }
        )
      );
    }

    // see of any members are becoming coordinators
    const memberToCoordinator = statuses.filter((s) => {
      const foundPod = members.some(
        (m) => s.id === m.podProfileId && s.status === 'coordinator'
      );
      return foundPod;
    });

    if (memberToCoordinator.length) {
      saves.push(
        axios.post(
          `${rootUrl}/api/coordinators/promote`,
          {
            podId: pod.id,
            members: memberToCoordinator,
          },
          { withCredentials: true }
        )
      );
    }

    //see if any members need to be deleted
    if (membersToDelete.length) {
      saves.push(
        axios.post(
          `${rootUrl}/api/members/delete`,
          {
            podId: pod.id,
            members: membersToDelete,
          },
          { withCredentials: true }
        )
      );
    }

    saves.push(
      axios.post(
        `${rootUrl}/api/pod/update`,
        { payload },
        { withCredentials: true }
      )
    );

    axios
      .all(saves)
      .then(
        axios.spread((...responses) => {
          const results = responses[responses.length - 1];
          //others will have results we aren't intereseted in.
          if (results.status === 200) {
            if (results.data.fail) {
              setMessage(results.data.message);
              setSeverity('error');
              setSnacking(true);
            } else {
              // haivng a race issue here with the get of the updated project happening before all tasks have completed. Reordering calls.
              // might be fine without the final get, results.data appears to be matching.
              axios
                .get(`${rootUrl}/api/pods/` + pod.id, { withCredentials: true })
                .then((resp) => {
                  if (resp.status === 200) {
                    setMembersToDelete([]);
                    setPod(resp.data);
                    setStatuses([]); //reset status for members list
                    setMessage(t('Pod updated!'));
                    setSeverity('success');
                    setSnacking(true);
                    setEditing(false);
                  }
                })
                .catch((err) => {
                  setMessage('Error updating pod!');
                  setSeverity('error');
                  setSnacking(true);
                });
            }
          }
        })
      )
      .catch((err) => {
        setMessage('Error updating pod!');
        setSeverity('error');
        setSnacking(true);
      });
  };

  const confirmDeletePod = () => {
    axios
      .post(
        `${rootUrl}/api/pod/delete`,
        { id: pod.id },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 200) {
          window.location.href = '/pods';
        }
      });
  };

  const approvePod = () => {
    axios
      .post(
        `${rootUrl}/api/pod/approve`,
        { id: pod.id },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 200) {
          setPod(response.data);
          setMessage('Pod approved!');
          setSeverity('success');
          setSnacking(true);
        }
      });
  };

  const leavePod = () => {
    axios
      .post(
        `${rootUrl}/api/pod/leave`,
        {
          podId: pod.id,
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 200) {
          window.location.href = '/pods';
        }
      });
  };

  //get pod from query string
  return (
    <Container maxWidth="xl" className={classes.container}>
      {/* Pod Page */}
      {!isNaN(selectedPod) &&
        selectedPod > 0 &&
        pod &&
        !loading &&
        ((isMember && pod.podCoordinators.length) || canEdit) && (
          <div className={styles.body}>
            <div className={classes.header}>
              <PodSelection
                history={history}
                pod={pod}
                myPods={myPods}
                canEdit={canEdit}
                isAdmin={isAdmin}
                editing={editing}
                setEditing={setEditing}
                selectedPod={selectedPod}
                setSelectedPod={setSelectedPod}
                saveChanges={saveChanges}
                setDeleteOpen={setDeleteOpen}
                approvePod={approvePod}
                setLeaveOpen={setLeaveOpen}
              />
            </div>
            <div className={`${classes.root} ${styles.formBody}`}>
              <PodDetails
                history={history}
                myPods={myPods}
                isAdmin={isAdmin}
                isCoordinator={isCoordinator}
                pod={pod}
                setPayload={setPayload}
                editing={editing}
                selectedPod={selectedPod}
                setSelectedPod={setSelectedPod}
              />
              <PodCoordinators
                pod={pod}
                editing={editing}
                coordinators={coordinators}
                setCoordinators={setCoordinators}
              />
              <PodMembers
                history={history}
                pod={pod}
                members={members}
                isCoordinator={isCoordinator}
                isAdmin={isAdmin}
                setMembers={setMembers}
                editing={editing}
                membersToDelete={membersToDelete}
                setMembersToDelete={setMembersToDelete}
                statuses={statuses}
                setStatuses={setStatuses}
              />
            </div>
          </div>
        )}
      {/* User Messages */}
      {/* user accessed page without selecting a pod */}
      {!selectedPod && !loading && (
        <Paper maxWidth="xs" className={styles.userMessageBox}>
          <img src={podImage} alt="Pods" />
          <Box className={styles.userMessageHeader}>
            {t('Welcome to the Community!')}
          </Box>
          <Box className={styles.userMessageMessage}>
            {t(
              'No Pod selected. Select a Pod from the left navigation, under Pods. Or you can join one by selecting “View or Join Pods” from the left navigation and choosing a local pod to join. If you do not see one in your local area, feel free to start one or join the global remote pod.'
            )}
          </Box>
        </Paper>
      )}
      {/* user accessed a pod but data is fetching*/}
      {!isNaN(selectedPod) && selectedPod && loading && (
        <Paper className={styles.userMessageBox}>
          <img src={podImage} alt="Pods" />
          <Box className={styles.userMessageHeader}>
            {t('Welcome to the Community!')}
          </Box>
          <Box className={styles.userMessageMessage}>
            <CircularProgress className={styles.podsLoading} />
          </Box>
        </Paper>
      )}
      {/* user accessed a pod but they are not a member or their membship is pending */}
      {!isNaN(selectedPod) &&
        selectedPod &&
        !loading &&
        !canEdit &&
        (!isMember || isPending) && (
          <Paper className={styles.userMessageBox}>
            <img src={podImage} alt="Pods" />
            <Box className={styles.userMessageHeader}>
              {!isPending && !isMember && t('Welcome to the Community!')}
              {isPending && t('Your membership is pending!')}
            </Box>
            <Box className={styles.userMessageMessage}>
              {!isPending &&
                !isMember &&
                t(
                  'You are not yet a member of this Pod, but you can join by selecting “View or Join Pods” from the left navigation and locating this pod to join'
                )}
              {isPending &&
                t(
                  `Your request to join this Pod is being reviewed by the Pod Coordinator. If you have not recieved access within 7 days please contact `
                )}
              {isPending && (
                <Link
                  href={`mailto:pods@500womenscientists.org?subject=Update on my membership for Pod "${
                    pod?.name || selectedPod
                  }"`}
                >
                  pods@500womenscientists.org
                </Link>
              )}
              .
            </Box>
          </Paper>
        )}

      {/* user accessed a pod and is only a member, but the pod doesn't have a coordinator yet */}
      {!isNaN(selectedPod) &&
        selectedPod &&
        pod &&
        !loading &&
        !canEdit &&
        isMember &&
        !pod.podCoordinators.length && (
          <Paper className={styles.userMessageBox}>
            <img src={podImage} alt="Pods" />
            <Box className={styles.userMessageHeader}>
              {t('The Pod currently does not have a Coordinator!')}
            </Box>
            <Box className={styles.userMessageMessage}>
              {t(
                `This Pod will open once a coordinator is assigned. If you are interested in becoming a coordinator of this Pod please email `
              )}
              <Link
                href={`mailto:pods@500womenscientists.org?subject=I'm interested in becoming a coordinator of Pod "${selectedPod}"`}
              >
                pods@500womenscientists.org
              </Link>
              .
            </Box>
          </Paper>
        )}
      <Snackbar
        open={snacking}
        autoHideDuration={6000}
        // anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          <AlertTitle>
            {t(
              severity[0].toUpperCase() +
                severity.substr(1, severity.length - 1)
            )}
          </AlertTitle>
          {message}
        </Alert>
      </Snackbar>
      <Dialog
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          {t('Are you sure you want to delete this Pod?')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            {t('This will completely remove')} <strong>{pod?.name}</strong>.{' '}
            {t('This operation cannot be undone.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={styles.cancelButton}
            onClick={() => setDeleteOpen(false)}
            color="secondary"
          >
            {t('Cancel')}
          </Button>
          <Button
            className={styles.deleteButton}
            onClick={confirmDeletePod}
            color="primary"
            autoFocus
          >
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={leaveOpen}
        onClose={() => setLeaveOpen(false)}
        aria-labelledby="leave-dialog-title"
        aria-describedby="leave-dialog-description"
      >
        <DialogTitle id="leave-dialog-title">
          {'Are you sure you want to leave this Pod?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="leave-dialog-description">
            {t('This will completely remove you from')}{' '}
            <strong>{pod.name}</strong>.
            {t(
              'Once removed, you can always rejoin this pod if you change your mind'
            )}
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={styles.cancelButton}
            onClick={() => setLeaveOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            className={styles.deleteButton}
            onClick={leavePod}
            color="primary"
            autoFocus
          >
            Leave
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PodForm;
