import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import '@fortawesome/fontawesome-free';
import 'components-font-awesome';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { Box, Link, IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 20,
  },
  container: {
    //overflow: 'auto',
    marginLeft: 15,
  },
  paper: {
    boxShadow: '0px 2px 6px 0 rgb(65 36 77 / 5%)',
    borderRadius: 0,
    padding: '32px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  table: {
    minWidth: 650,
    '& .MuiTableRow-head': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: '#6e3694',
      fontFamily: 'Oswald',
      fontSize: '16px',
      fontWeight: 500,
      letterSpacing: '0.2px',
      textTransform: 'uppercase',
    },
  },
  memberGrid: {
    maxHeight: 500,
  },
  selectWrapper: {
    width: '100%',
    textAlign: 'left',
  },
  hyperlink: {
    fontFamily: 'Archivo Narrow',
    fontSize: '1rem',
    color: '#6e3694 !important',
    textDecoration: 'none !important',
    '&:hover': {
      color: '#2c0040 !important',
      textDecoration: 'underline !important',
    },
  },
  cellName: {
    color: 'rgba(26,26,26,.9)',
    fontWeight: 700,
  },
  cellEmail: {
    color: '#6e3694',
    fontWeight: 700,
    '&:hover': {
      color: '#2c0040 !important',
      textDecoration: 'underline !important',
    },
  },
  cellRole: {
    color: 'rgba(26,26,26,.7)',
    fontWeight: 500,
  },
}));

const PodCoordinators = (props) => {
  const { pod, editing, coordinators, setCoordinators } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    if (pod) {
      setCoordinators(
        [...pod.podCoordinators].map((coordinator) => {
          return {
            podProfileId: coordinator.podProfileId,
            name: coordinator.podProfile.name,
            email: coordinator.podProfile.user?.email,
            role: coordinator.role,
            isDelete: false,
          };
        })
      );
    }
  }, [pod, setCoordinators]);

  useEffect(() => {
    if (!editing) {
      //reset delete on cancel
      setCoordinators((coords) => {
        return coords.map((coordinator) => {
          return { ...coordinator, isDelete: false };
        });
      });
    }
  }, [editing, setCoordinators]);

  const changeRole = (coordinator, role) => {
    const copiedCoords = [...coordinators];
    const found = copiedCoords.find(
      (c) => c.podProfileId === coordinator.podProfileId
    );
    found.role = role;
    setCoordinators(copiedCoords);
  };

  const markForDelete = (coordinator) => {
    const copiedCoords = [...coordinators];
    const found = copiedCoords.find(
      (c) => c.podProfileId === coordinator.podProfileId
    );
    found.isDelete = true;
    setCoordinators(copiedCoords);
  };

  //get pod from query string
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography className={styles.header} gutterBottom>
          {t('Pod Coordinators')}
        </Typography>

        <Box className={styles.content}>
          <Typography className={`${styles.text} ${styles.sectionDescription}`}>
            {t(
              'The following women are your local pod coordinators, feel free to reach out to them with any questions. You can also contact the'
            )}{' '}
            <Link
              variant="body2"
              className={classes.hyperlink}
              href={`mailto:pods@500womenscientists.org`}
            >
              {'500 Women Scientists pods leadership team'}
            </Link>
            {t(', if you have further concerns.')}
          </Typography>
          <TableContainer
            component={Paper}
            className={`${styles.coordinatorTable}`}
          >
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={styles.tableHeader}>
                    {t('Coordinator')}
                  </TableCell>
                  <TableCell className={styles.tableHeader}>
                    {t('Email')}
                  </TableCell>
                  <TableCell className={styles.tableHeader}>
                    {t('Role')}
                  </TableCell>
                  {editing && (
                    <TableCell className={styles.tableHeader}>
                      {t('Delete')}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {pod &&
                  coordinators
                    .filter((c) => !c.isDelete)
                    .map((coordinator) => (
                      <TableRow key={coordinator.podProfileId}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={`${styles.tableCell} ${classes.cellName}`}
                        >
                          {coordinator.name}
                        </TableCell>
                        <TableCell
                          className={`${styles.tableCell} ${classes.cellEmail}`}
                        >
                          {coordinator.email}
                        </TableCell>
                        <TableCell
                          className={`${styles.tableCell} ${classes.cellRole}`}
                        >
                          {!editing &&
                            pod.podCoordinators?.find(
                              (p) => p.podProfileId === coordinator.podProfileId
                            )?.role}
                          {editing && (
                            <TextField
                              value={coordinator.role}
                              style={{ width: '100%' }}
                              align="left"
                              onChange={({ target }) =>
                                changeRole(coordinator, target.value)
                              }
                            />
                          )}
                        </TableCell>
                        {editing && (
                          <TableCell className={styles.tableCell}>
                            <IconButton
                              onClick={() => markForDelete(coordinator)}
                              aria-label="Delete Coordinator"
                            >
                              <i className="fa fa-times" />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </div>
  );
};

export default PodCoordinators;
