import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import styles from './index.module.scss';
import '@fortawesome/fontawesome-free';
import 'components-font-awesome';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import {
  Button,
  Box,
  useMediaQuery,
  ListSubheader,
  TextField,
  Typography,
} from '@material-ui/core';
import { VariableSizeList } from 'react-window';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 20,
  },
  container: {
    //overflow: 'auto',
    marginRight: 15,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  table: {
    minWidth: 650,
  },
  selectWrapper: {
    width: '50%',
    textAlign: 'left',
  },
  active: {
    color: '#6e3694',
    cursor: 'pointer',
  },
  buttonWrapper: {
    display: 'inline',
  },
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
  auto: {
    fontFamily: "'Archivo Narrow' !important",
    '& .MuiAutocomplete-endAdornment': {
      top: 'unset',
    },
  },
  inline: {
    display: 'inline',
  },
}));

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

const OPTIONS = (pods) =>
  pods
    .map((m) => m.name)
    .sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));

const PodSelection = (props) => {
  const {
    history,
    pod,
    myPods,
    isAdmin,
    canEdit,
    setEditing,
    editing,
    selectedPod,
    //setSelectedPod,
    saveChanges,
    setDeleteOpen,
    approvePod,
    setLeaveOpen,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const handleChange = useCallback(
    (event) => {
      const pod = myPods.find((p) => p.name === event.target.innerText);
      if (pod) {
        // update url
        history.push('/pods/' + pod.id);
        // and update form
        // setSelectedPod(pod.id);
      }
    },
    [myPods, history]
  );

  const auto = useMemo(() => {
    return (
      <FormControl className={classes.selectWrapper}>
        <Autocomplete
          id="virtualize-demo"
          style={{ width: 400 }}
          className={classes.auto}
          disableListWrap
          classes={classes}
          value={myPods.find((p) => p.id === selectedPod)?.name}
          ListboxComponent={ListboxComponent}
          renderGroup={renderGroup}
          onChange={handleChange}
          options={OPTIONS(myPods)}
          //groupBy={(option) => option[0].toUpperCase()}
          renderInput={(params) => (
            <TextField
              className={classes.auto}
              {...params}
              placeholder={t('Pod selection')}
            />
          )}
          renderOption={(option) => <Typography noWrap>{option}</Typography>}
        />
      </FormControl>
    );
  }, [classes, handleChange, myPods, selectedPod, t]);

  //get pod from query string
  return (
    <Box className={classes.root}>
      <Box className={classes.section}>
        {auto}
        <div className={styles.toolbar}>
          <div className={classes.inline}>
            {!editing && !isAdmin && (
              <Button
                className={styles.deleteButton}
                onClick={() => setLeaveOpen(true)}
              >
                {t('Leave Pod')}
              </Button>
            )}
          </div>
          {canEdit && (
            <div className={classes.inline}>
              {!editing && pod?.isNew && isAdmin && (
                <Button className={styles.saveButton} onClick={approvePod}>
                  {t('Approve Pod')}
                </Button>
              )}
              {editing && (
                <Button
                  className={styles.cancelButton}
                  onClick={() => setEditing(false)}
                >
                  {t('Cancel')}
                </Button>
              )}
              {!editing && isAdmin && !pod?.isRemote && (
                <Button
                  className={`${styles.deleteButton} ${styles.leftIndent}`}
                  disabled={!canEdit}
                  onClick={() => {
                    setDeleteOpen(true);
                  }}
                >
                  {t('Delete Pod')}
                </Button>
              )}
              {!editing && (
                <Button
                  className={`${styles.purpleButton} ${styles.leftIndent}`}
                  onClick={() => setEditing(true)}
                >
                  {t('Edit Pod')}
                </Button>
              )}
              {editing && (
                <Button
                  className={`${styles.purpleButton} ${styles.leftIndent}`}
                  onClick={saveChanges}
                >
                  {t('Save Changes')}
                </Button>
              )}
            </div>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default PodSelection;
