import React from 'react';
// import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import Jane from '../../assets/images/gap/people/Jane.jpg';
import Farah from '../../assets/images/gap/people/Farah.jpg';
import Virginia from '../../assets/images/gap/people/virginia.png';

const GapAbout = (props) => {
  return (
    <div className={styles.aboutPage}>
      <div className={styles.purple}>
        <div className={styles.textContainer}>
          <div className={styles.aboutFTG}>
            <h1>About Fix the Gap</h1>
            <p>
              Founded and powered by{' '}
              <a href="https://500womenscientists.org/">500 Women Scientists</a>
              , Fix the Gap is a database where you can explore over 1,000
              honoraria received for speaking engagements by individuals working
              in STEMM fields.
            </p>
          </div>
        </div>
      </div>
      <div className={`${styles.textContainer} ${styles.flex}`}>
        <div className={styles.forty}>
          <h1>What’s the story behind Fix the Gap?</h1>
        </div>
        <div className={styles.sixty}>
          <p>
            Fix The Gap was created to ensure that the contributions of women
            and gender diverse folks in science are acknowledged and compensated
            fairly. The database serves to normalize compensation for STEMM
            professionals for their expertise, and make it easy for anyone,
            especially those belonging to historically excluded communities, to
            seek compensation for their expertise and point to a resource to
            justify their “ask.”
          </p>
          <p>
            Honoraria can be filtered by various factors, including country,
            gender, age range, and the type of speaking engagement (e.g., talks,
            panels, workshops, keynotes, and guest lectures). Honoraria for the
            Fix The Gap database were crowdsourced via a 500 Women Scientists’
            survey in early 2021 (
            <a href="https://500womenscientists.org/fix-the-gap">
              learn more here
            </a>
            ).
          </p>
          <p>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSf0_YPcWNMsMJDD1OxJfGf5lgSKbjrNSQkf_dL0wfDCATU_Gw/viewform">
              Help grow our database further
            </a>{' '}
            by anonymously submitting honoraria you have received for speaking
            engagements in the past.
          </p>
          <p>
            Stay tuned for more resources as we continue to explore pay equity!
            Have ideas, feedback or questions? For inquiries or requests related
            to Fix The Gap, please contact:{' '}
            <a href="mailto:survey@500womenscientists.org">
              survey@500womenscientists.org
            </a>
          </p>
        </div>
      </div>
      <div className={styles.yellow}>
        <h3>The Founders</h3>
        <div className={styles.photoContainer}>
          <div className={styles.person}>
            <img src={Jane} />
            <p>
              <b>Jane Zelikova</b> (she/her) is a co-founder of 500 Women
              Scientists and a climate change scientist working at the
              intersection of science and policy.{' '}
              <a href="https://www.janezelikova.com/">www.janezelikova.com</a>
            </p>
          </div>
          <div className={styles.person}>
            <img src={Farah} />
            <p>
              <b>Farah Qaiser</b> (she/her) is a genomics researcher by
              training, and enjoys getting involved in different science
              outreach, communication and policy initiatives to help build an
              inclusive and engaging science culture.
            </p>
          </div>
          <div className={styles.person}>
            <img src={Virginia} />
            <p>
              <b>Virginia Schutte</b> (she/her) is a storyteller, media
              strategist, and trainer who experiments with how people interact
              with science.{' '}
              <a href="https://www.VirginiaSchutte.com">
                www.VirginiaSchutte.com
              </a>
              <br />
              Twitter <a href="https://twitter.com/vgwschutte">@vgwschutte</a>
            </p>
          </div>
        </div>
      </div>
      <div className={styles.darkPurple}>
        <div className={styles.surveyContainer}>
          <h3 className={styles.white}>
            Have you received an honorarium for a STEMM-related speaking
            engagement?
          </h3>
          <p>
            Share the details anonymously in our survey, and help us grow 500
            Women Scientists’ Fix The Gap honoraria database.
          </p>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSf0_YPcWNMsMJDD1OxJfGf5lgSKbjrNSQkf_dL0wfDCATU_Gw/viewform">
            <button>LAUNCH SURVEY</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default GapAbout;
