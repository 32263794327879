// @flow
import React, { useContext, useState } from 'react';
import ReactGA from 'react-ga';
import axios from 'axios';
import { rootUrl } from '../../../config';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import StoreContext from '../../../state/context/store';
import {
  setSearchResults,
  setSearchTerm,
  setSearching,
  setSelectedSpecialization,
  setSelectedDisciplines,
  setSelectedMedicalDisciplines,
  setSelectedMedicalSubDisciplines,
  setSelectedLocations,
  setSelectedInterests,
  setSelectedRaces,
  setSelectedGenders,
  setSelectedLanguages,
  setActiveDisplay,
} from '../../../state/actions/search';
import MetricsManager from '../../../service/metrics';
import { SnackContext } from '../../../state/context/snack';
import { Button } from '@material-ui/core';
import { LayoutContext } from '../../../state/context/layout';
import SearchBox from '../../SearchBox';

const mm = MetricsManager.getInstance();

const LandingSearch = (props) => {
  const { t } = useTranslation();
  const [store, dispatch] = useContext(StoreContext);
  const { search } = store;
  const { isSearching } = search;
  const [searchValue, setSearchValue] = useState(search.searchTerm);
  const { placeholder, history } = props;

  const { setAction, setMessage, setSeverity } = useContext(SnackContext);
  const { phoneActive } = useContext(LayoutContext);

  const searchWomen = () => {
    setSearchResults(dispatch, []);
    //clear any stored filters
    setSelectedSpecialization(dispatch, null);
    setSelectedDisciplines(dispatch, []);
    setSelectedMedicalDisciplines(dispatch, []);
    setSelectedMedicalSubDisciplines(dispatch, []);
    setSelectedLocations(dispatch, []);
    setSelectedInterests(dispatch, []);
    setSelectedRaces(dispatch, []);
    setSelectedGenders(dispatch, []);
    setSelectedLanguages(dispatch, []);

    const start = new Date().getTime();
    setSearching(dispatch, true);
    axios
      .post(
        `${rootUrl}/api/search`,
        {
          search: searchValue,
        },
        {
          withCredentials: true,
        }
      )
      .then((results) => {
        setSearchTerm(dispatch, searchValue);
        setActiveDisplay(dispatch, 'search');
        //if search is too large, warn user
        if (results.data.error) {
          setAction(true);
          setMessage(
            `${t('search.tooLarge_part1')} ${Number(
              results.data.total
            ).toLocaleString(undefined, {
              minimumFractionDigits: 0,
            })} ${t('search.tooLarge_part2')}.`
          );
          setSeverity('warning');
          //snack attack
          return;
        }

        setSearchResults(dispatch, results.data);

        mm.addMetrics(
          results.data.map((r) => {
            return {
              userId: r.userId,
              appId: 1, //gage
              gageProfileId: r.id,
              podProfileId: null,
              metric: 'search',
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSearching(dispatch, false);
        const time = new Date().getTime() - start;
        if (store.me.tracking) {
          ReactGA.timing({
            category: 'Search',
            variable: 'load',
            value: time, // in milliseconds
            label: 'Search API',
          });
        }
        history.push('/search');
      });
    if (store.me.tracking) {
      ReactGA.event({
        category: 'Search',
        action: 'Term',
        label: searchValue,
      });
    }
  };

  const handleKeyPress = (event) => {
    setSearchValue(event.target.value);
    if (event.key === 'Enter') {
      searchWomen();
    }
  };

  const clearSearch = () => {
    setSearchValue('');
  };

  return (
    <div className={styles.container}>
      <SearchBox
        placeholder={placeholder}
        value={searchValue}
        clear={clearSearch}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
        onKeyDown={handleKeyPress}
        search={searchWomen}
      />
      <Button
        className={`${styles.search} ${
          phoneActive ? styles.search_mobile : null
        }`}
        onClick={searchWomen}
        // disabled={true}
        variant="outlined"
      >
        {phoneActive ? t('Go') : t('Explore')}{' '}
        {isSearching && (
          <i
            className={`${styles.spinner} fas fa-circle-notch fa-spin`}
            style={{ width: '16px', height: '16px', fontSize: '16px' }}
          ></i>
        )}
      </Button>
    </div>
  );
};

export default LandingSearch;
