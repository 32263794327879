// @flow
import React, { Component } from 'react';
// import { Link, withRouter } from 'react-router-dom';
import styles from './index.module.scss';
import xIcon from '../../../assets/images/gap/popupX.svg';

class SurveyPopup extends Component {
  constructor() {
    super();
  }
  handleHidePopup = () => {
    const { handleHidePopup } = this.props;
    handleHidePopup();
  };

  render() {
    return (
      <div className={styles.showPopup}>
        <div className={styles.hed}>
          <h2>
            Have you received an honorarium for a STEMM-related speaking
            engagement?
          </h2>
          <img
            className={styles.xIcon}
            src={xIcon}
            onClick={this.handleHidePopup}
          />
        </div>
        <div className={styles.content}>
          <p>
            Share the details anonymously in our survey, and help us grow 500
            Women Scientists’ Fix The Gap honoraria database.
          </p>
          <button onClick={this.handleHidePopup}>start survey</button>
        </div>
      </div>
    );
  }
}

export default SurveyPopup;
