import React, { useState, useEffect, useContext, useRef } from 'react';
import { rootUrl } from '../../config';
import { Button } from '@material-ui/core';
import styles from './index.module.scss';
// import { useTranslation } from 'react-i18next';
import axios from 'axios';
import GapChart from '../chart';
import GapTable from '../table';
import GapTableMobile from '../mobileTable';
import ChartToggleButton from '../toggleButton';
import { LayoutContext } from '../../state/context/layout';
import Close from '../../assets/images/gap/close.svg';
import FilterIcon from '../../assets/images/gap/filterIcon.svg';
import ChartIcon from '../../assets/images/gap/chartIcon.svg';
import TableIcon from '../../assets/images/gap/tableIcon.svg';
// import Close from '../../components/Gage/LandingSearch';
// import StoreContext from '../../state/context/store';
import * as d3 from 'd3';
import { useSpring, animated } from 'react-spring';

const GapLanding = (props) => {
  // const { t } = useTranslation();
  const [rawData, setRawData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [data, setData] = useState();
  const [countryList, setCountryList] = useState();
  const [formData, setFormData] = useState({});
  const [dots, setDots] = useState([]);
  const [minimum, setMinimum] = useState();
  const [median, setMedian] = useState();
  const [mean, setMean] = useState();
  const [maximum, setMaximum] = useState();
  const [totalNumber, setTotalNumber] = useState();
  // toggle between chart and table
  const [view, setView] = useState('chart');
  const { phoneActive, tabletActive } = useContext(LayoutContext);
  const [eventType, setEventType] = useState('form click');
  const [viewButtonText, setViewButtonText] = useState('View table');
  const toggleRef = useRef();

  // equations for violin chart weighting
  const kde = (kernel, thds) => (V) =>
    thds.map((t) => [t, d3.mean(V, (d) => kernel(t - d))]);
  const epanechnikov = (bandwidth) => (x) => {
    return Math.abs((x /= bandwidth)) <= 1
      ? (0.75 * (1 - x * x)) / bandwidth
      : 0;
  };
  const bwEstimate = (data) => {
    const A = Math.min(
      d3.deviation(data),
      (d3.quantile(data, 0.75) - d3.quantile(data, 0.25)) / 1.34
    );
    return 0.9 * A * Math.pow(data.length, -1 / 5);
  };

  useEffect(() => {
    /* it will be called when queues did update */
    console.log('raw data');
    if (rawData) {
      formatChartData(rawData);
    }
  }, [filteredData]);

  useEffect(() => {
    /* it will be called when queues did update */
    console.log('do chart data stuff');
    console.log(formData);
    // console.log(rawData)
    if (rawData) {
      let filteredData = [];
      if (Object.keys(formData).length === 0) {
        filteredData = rawData;
      } else {
        Object.keys(formData).forEach((key, i) => {
          let cData = filteredData;
          if (i === 0) {
            cData = rawData;
          }
          if (formData[key] === 'default') {
            // pass
            filteredData = cData;
          } else {
            if (key === 'expertiseStemField') {
              filteredData = cData.filter((item) =>
                item[key].split(', ').includes(formData[key])
              );
            } else {
              filteredData = cData.filter(
                (item) => item[key] === formData[key]
              );
            }
          }
        });
      }
      doMath(filteredData);
      setFilteredData(filteredData);
      console.log(filteredData);
    }
  }, [formData]);

  useEffect(() => {
    const fetchData = async () => {
      const getSurveys = await axios.get(`${rootUrl}/api/surveys`, {
        withCredentials: true,
      });
      const tmpData = getSurveys.data.map((item) => ({
        ...item,
        honorariaValue: Math.round(parseFloat(item.honorariaValue) * 100) / 100,
        year: parseInt(item.year),
        numberOfHonorariums: parseInt(item.numberOfHonorariums),
      }));
      console.log(tmpData);
      setRawData(tmpData);
      setFilteredData(tmpData);
      doMath(tmpData);
    };

    fetchData().catch(console.error);
  }, []);

  const doMath = (data) => {
    let sorted = data
      .map((d) => parseInt(d.honorariaValue))
      .sort(function (a, b) {
        return a - b;
      });
    // minimum
    if (isNaN(sorted[0])) {
      setMinimum('--');
    } else {
      setMinimum(d3.format(',')(sorted[0]));
    }

    // maximum
    if (isNaN(sorted[sorted.length - 1])) {
      setMaximum('--');
    } else {
      setMaximum(d3.format(',')(sorted[sorted.length - 1]));
    }

    // Median
    let getMedian = sorted[Math.ceil(sorted.length / 2)];
    if (isNaN(getMedian)) {
      setMedian('--');
    } else {
      setMedian(d3.format(',')(getMedian));
    }

    // Mean
    let sum = sorted.reduce((pv, cv) => pv + cv, 0);
    if (isNaN(Math.ceil(sum / sorted.length))) {
      setMean('--');
    } else {
      setMean(d3.format(',')(Math.ceil(sum / sorted.length)));
    }

    setTotalNumber(data.length);
  };

  const formatChartData = () => {
    let densities = [];
    [
      'Workshop',
      'Symposium',
      'Panel',
      'Keynote',
      'Invited Talk',
      'Guest Lecture',
      // "Miscellaneous"
    ].forEach((d, i) => {
      const raw = filteredData
        .filter((f) => f['talkType'] === d)
        .map((g) => parseInt(g['honorariaValue']))
        .sort((a, b) => a - b);
      const data2 = kde(epanechnikov(bwEstimate(raw)), raw)(raw);
      data2.forEach((f) =>
        densities.push({
          value: f[0],
          weight: f[1],
          'Honoraria Type': d,
          index: i,
          resp: filteredData.filter(
            (item) =>
              item['talkType'] === d &&
              parseInt(item['honorariaValue']) === f[0]
          ).length,
          m: d3.median(raw),
        })
      );
    });
    setData(densities);
    getCountries(rawData);

    let singleKeys = [];
    let group = d3.group(Object.values(filteredData), (d) => d['talkType']);
    let keys = Array.from(group.keys());
    keys.forEach((key) => {
      if (group.get(key).length < 2) {
        singleKeys.push(key);
      }
    });
    setDots(singleKeys);
  };

  const getCountries = (data) => {
    let tmpCountries = [];
    data.forEach((d) => {
      if (!tmpCountries.includes(d.currentCountry)) {
        tmpCountries.push(d.currentCountry);
      }
    });
    setCountryList(tmpCountries.sort());
  };

  const resetFilters = () => {
    console.log('reset filters');
    let resetForm = {
      talkType: 'default',
      sectorOfSpeakingEngagement: 'default',
      personOrVirtual: 'default',
      expertiseStemField: 'default',
      ageRange: 'default',
      genderIdentity: 'default',
      currentCountry: 'default',
      highestDegree: 'default',
      isPursuingDegree: 'default',
      speakingExperienceYears: 'default',
      numberOfHonorariums: 'default',
    };
    setFormData(resetForm);
  };

  const handleChange = (event) => {
    // console.log(event.target.name, event.target.value);
    const name = event.target.name;
    const value = event.target.value;
    // this.setState({ dashboard: { ...dashboard, layouts: { lg: newLayout } } });
    setEventType('form click');
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleToggle = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const handleToggleMobile = () => {
    setEventType('toggle chart/table');
    if (toggleRef.current.value === 'table') {
      setView('chart');
      setViewButtonText('View table');
    } else if (toggleRef.current.value === 'chart') {
      setView('table');
      setViewButtonText('View chart');
    }
  };

  const handleSubmit = (event) => {
    // alert('A name was submitted: ' + this.state.value);
    // event.preventDefault();
  };

  const CountryListOptions = () => {
    return countryList.map(function (country, i) {
      return (
        <option value={country} key={i}>
          {country}
        </option>
      );
    });
  };

  const Form = () => {
    return (
      <>
        <div className={styles.top}>
          <h1>Filters</h1>
          <Button className={styles.button} onClick={() => resetFilters()}>
            RESET FILTERS
          </Button>
          <img
            src={Close}
            className={styles.closeButton}
            onClick={handleToggleDrawer}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.speakingEngagement}>
            <h2>Speaking engagement characteristics</h2>
            <label>
              Type of speaking engagement
              <select
                value={formData.talkType}
                name={'talkType'}
                onChange={handleChange}
              >
                <option value="default">All Speaking Engagement Types</option>
                <option value="Workshop">Workshop</option>
                <option value="Symposium">Symposium</option>
                <option value="Keynote">Keynote</option>
                <option value="Panel">Panel</option>
                <option value="Invited Talk">Invited Talk</option>
                <option value="Guest Lecture">Guest Lecture</option>
              </select>
            </label>
            <label>
              Organization type
              <select
                value={formData.sectorOfSpeakingEngagement}
                name={'sectorOfSpeakingEngagement'}
                onChange={handleChange}
              >
                <option value="default">All Organization Types</option>
                <option value="Academia">Academia</option>
                <option value="Non-profit organization">
                  Non-profit organization
                </option>
                <option value="For-profit company or corporate entity">
                  For-profit company or corporate entity
                </option>
                <option value="Community group">Community group</option>
                <option value="Government">Government</option>
                <option value="Healthcare">Healthcare</option>
                <option value="School">School</option>
                <option value="NA">NA</option>
              </select>
            </label>
            <label>
              Talk delivery type
              <select
                value={formData.personOrVirtual}
                name={'personOrVirtual'}
                onChange={handleChange}
              >
                <option value="default">All Talk Delivery Types</option>
                <option value="In-person">In-person</option>
                <option value="Virtual">Virtual</option>
              </select>
            </label>
            {/* <input type="submit" value="Submit" /> */}
          </div>
          <div className={styles.honorariaRecipient}>
            <h2>Honoraria recipient characteristics</h2>
            <label>
              STEMM field
              <select
                value={formData.expertiseStemField}
                name={'expertiseStemField'}
                onChange={handleChange}
              >
                <option value="default">All STEMM fields</option>
                <option value="Science">Science</option>
                <option value="Technology">Technology</option>
                <option value="Engineering">Engineering</option>
                <option value="Mathematics">Mathematics</option>
                <option value="Medicine">Medicine</option>
                <option value="Interdisciplinary">Interdisciplinary</option>
              </select>
            </label>
            <label>
              Age
              <select
                value={formData.ageRange}
                name={'ageRange'}
                onChange={handleChange}
              >
                <option value="default">All Ages</option>
                <option value="20-30">20-30</option>
                <option value="31-40">31-40</option>
                <option value="41-50">41-50</option>
                <option value="51-60">51-60</option>
                <option value="61-70">61-70</option>
                <option value=">70">70+</option>
                <option value="No Answer">No Answer</option>
              </select>
            </label>
            <label>
              Gender
              <select
                value={formData.genderIdentity}
                name={'genderIdentity'}
                onChange={handleChange}
              >
                <option value="default">All Genders</option>
                <option value="Gender queer">Gender queer</option>
                <option value="Gender-fluid">Gender-fluid</option>
                <option value="I prefer not to answer">
                  I prefer not to answer
                </option>
                <option value="Man">Man</option>
                <option value="Multiple Identities">Multiple Identities</option>
                <option value="Non-binary">Non-binary</option>
                <option value="Woman">Woman</option>
              </select>
            </label>
            <label>
              Country
              <select
                value={formData.currentCountry}
                name={'currentCountry'}
                onChange={handleChange}
              >
                <option value="default">Country</option>
                {countryList && <CountryListOptions />}
              </select>
            </label>
            <label>
              Highest Degree
              <select
                value={formData.highestDegree}
                name={'highestDegree'}
                onChange={handleChange}
              >
                <option value="default">Highest Degree</option>
                <option value="Medical degree (MD)">Medical degree (MD)</option>
                <option value="Doctoral degree (PhD - research-track)">
                  Doctoral degree (PhD - research-track)
                </option>
                <option value="Doctoral degree (Professional e.g., EdD, PharmD, DBA)">
                  Doctoral degree (Professional e.g., EdD, PharmD, DBA)
                </option>
                <option value="Professional Law Degree (i.e., J.D.)">
                  Professional Law Degree (i.e., J.D.)
                </option>
                <option value="Master's degree (research-track)">
                  Master's degree (research-track)
                </option>
                <option value="Master's degree (professional or course-based)">
                  Master's degree (professional or course-based)
                </option>
                <option value="Bachelor’s degree">Bachelor’s degree</option>
                <option value="High school diploma">High school diploma</option>
                <option value="Medical degree (MD)">Medical degree (MD)</option>
                <option value="Professional Master's of Business Administration (i.e., M.B.A.)">
                  Professional Master's of Business Administration (i.e.,
                  M.B.A.)
                </option>
                <option value="None">None</option>
              </select>
            </label>
            <label>
              Degree being pursued
              <select
                value={formData.isPursuingDegree}
                name={'isPursuingDegree'}
                onChange={handleChange}
              >
                <option value="default">Degree Pursued</option>
                <option value="None">None</option>
                <option value="Doctoral degree (PhD - research-track)">
                  Doctoral degree (PhD - research-track)
                </option>
                <option value="Doctoral degree (Professional e.g., EdD, PharmD, DBA)">
                  Doctoral degree (Professional e.g., EdD, PharmD, DBA)
                </option>
                <option value="Master's degree (professional or course-based)">
                  Master's degree (professional or course-based)
                </option>
                <option value="Bachelor’s degree">Bachelor’s degree</option>
              </select>
            </label>
            <label>
              Years of speaking
              <select
                value={formData.speakingExperienceYears}
                name={'speakingExperienceYears'}
                onChange={handleChange}
              >
                <option value="default">Years Of Speaking</option>
                <option value="< 1 year">&lt; 1 year</option>
                <option value="1-5 years">1-5 years</option>
                <option value="5-10 years">5-10 years</option>
                <option value="10-15 years">10-15 years</option>
                <option value="15-20 years">15-20 years</option>
                <option value="> 20 years">&gt; 20 years</option>
              </select>
            </label>
            <label>
              Total number of paid talks
              <select
                value={formData.numberOfHonorariums}
                name={'numberOfHonorariums'}
                onChange={handleChange}
              >
                <option value="default">Total # Paid Talks</option>
                <option value="0">0</option>
                <option value="1-5 talks">1-5 talks</option>
                <option value="6-10 talks">6-10 talks</option>
                <option value="11-15 talks">11-15 talks</option>
                <option value="16-20 talks">16-20 talks</option>
                <option value="21-50 talks">21-50 talks</option>
                <option value="> 50 talks">&gt; 50 talks</option>
              </select>
            </label>
          </div>
        </form>
      </>
    );
  };

  const StatBox = () => {
    return (
      <div className={styles.statBox}>
        <div className={styles.top}>
          <div className={styles.half}>
            {Object.keys(formData).length > 1 && (
              <span>
                {Object.keys(formData).length === 11
                  ? 'All Results'
                  : `${Object.keys(formData).length} filters`}{' '}
              </span>
            )}
            {Object.keys(formData).length === 1 && (
              <span>{Object.keys(formData).length} filter</span>
            )}
            {Object.keys(formData).length < 1 && <span>All Results</span>}
          </div>
          <div className={styles.half}>{totalNumber} Entries</div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.statistic}>
            Minimum
            <div>{minimum} USD</div>
          </div>
          <div className={styles.statistic}>
            Median
            <div>{median} USD</div>
          </div>
          <div className={styles.statistic}>
            Mean
            <div>{mean} USD</div>
          </div>
          <div className={styles.statistic}>
            Maximum
            <div>{maximum} USD</div>
          </div>
        </div>
      </div>
    );
  };

  const [isDrawerShowing, setDrawerShowing] = useState(false);

  const handleToggleDrawer = () => {
    setEventType('toggle drawer');
    setDrawerShowing(!isDrawerShowing);
  };

  const Drawer = ({ isDrawerShowing }) => {
    console.log(isDrawerShowing, eventType);
    let tmp = [];
    if (eventType === 'toggle drawer') {
      tmp = {
        from: {
          transform: isDrawerShowing
            ? `translate(0px, 0vh)`
            : `translate(0px, -100vh)`,
        },
        to: {
          transform: isDrawerShowing
            ? `translate(0px, -100vh)`
            : `translate(0px, 0vh)`,
        },
        config: {
          duration: 650,
        },
      };
    } else {
      tmp = {
        transform: isDrawerShowing
          ? `translate(0px, -100vh)`
          : `translate(0px, 0vh)`,
        config: {
          duration: 650,
        },
      };
    }

    const props = useSpring(tmp);

    return (
      <animated.div style={props}>
        <div className={styles.mobileMenu}>
          <Form />
        </div>
      </animated.div>
    );
  };

  const NoEntries = () => {
    return (
      <div className={styles.noEntries}>
        No matching entries. Please adjust filters to see results.
      </div>
    );
  };

  return (
    <>
      {!phoneActive && !tabletActive ? (
        <div className={styles.FTG}>
          <div className={styles.container}>
            <div className={styles.leftPanel}>
              <Form />
            </div>
            <div className={styles.chartSide}>
              <ChartToggleButton handleToggle={handleToggle} view={view} />
              {view === 'chart' && (
                <>
                  <h1 className={styles.chartLabel}>
                    Honoraria value (USD/hour) by engagement and recipient
                    characteristics
                  </h1>
                  <StatBox />
                  {totalNumber !== 0 && (
                    <GapChart data={data} dots={dots} view="desktop" />
                  )}
                  {totalNumber === 0 && <NoEntries />}
                </>
              )}
              {view === 'table' && (
                <>
                  {totalNumber !== 0 && (
                    <>{filteredData && <GapTable data={filteredData} />}</>
                  )}
                  {totalNumber === 0 && <NoEntries />}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          {/* <ChartToggleButton handleToggle={handleToggle} view={view} /> */}
          {view === 'chart' && (
            <div className={styles.mobileChart}>
              <h1 className={styles.chartLabel}>
                Summary: Honoraria Value (USD/Hour)
              </h1>
              <StatBox />
              {totalNumber !== 0 && (
                <GapChart data={data} dots={dots} view="mobile" />
              )}
              {totalNumber === 0 && <NoEntries />}
            </div>
          )}
          {view === 'table' && (
            <>
              {totalNumber !== 0 && (
                <>{filteredData && <GapTableMobile data={filteredData} />}</>
              )}
              {totalNumber === 0 && (
                <div className={styles.mobileNoEntries}>
                  <NoEntries />
                </div>
              )}
            </>
          )}
          <div className={styles.buttonNav}>
            {isDrawerShowing && (
              <button
                className={`${styles.filterButton} ${styles.apply}`}
                onClick={handleToggleDrawer}
              >
                Apply
              </button>
            )}
            {!isDrawerShowing && (
              <>
                <button
                  className={styles.filterButton}
                  onClick={handleToggleDrawer}
                >
                  {/* {isDrawerShowing ? 'Close Filter' : 'Filter'} */}
                  <img src={FilterIcon} />
                  <div>Filter</div>
                </button>
                <button
                  value={view}
                  onClick={handleToggleMobile}
                  ref={toggleRef}
                >
                  {view === 'chart' && <img src={TableIcon} />}
                  {view === 'table' && <img src={ChartIcon} />}
                  <div>{viewButtonText}</div>
                </button>
              </>
            )}
          </div>
          <Drawer isDrawerShowing={isDrawerShowing} />
        </>
      )}
    </>
  );
};

export default GapLanding;
