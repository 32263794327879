import React from 'react';
// import { useTranslation } from 'react-i18next';

const Feedback = (props) => {
  // const { t } = useTranslation();

  return (
    <>
      <p>feedback</p>
    </>
  );
};

export default Feedback;
