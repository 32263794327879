// @flow
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Link, withRouter, useLocation } from 'react-router-dom';
import styles from './index.module.scss';
// import SideDrawer from '../SideDrawer';
import StoreContext from '../../state/context/store';
import { logout } from '../../state/actions/me';
import { rootUrl } from '../../config';
import podsIcon from '../../assets/images/pods/navigation/Pod-white.svg';
import leadershipIcon from '../../assets/images/pods/navigation/Leadership.svg';
import accountIcon from '../../assets/images/pods/navigation/My-Account.svg';
import mapIcon from '../../assets/images/pods/navigation/View-Join-Pods.svg';
import adminIcon from '../../assets/images/pods/navigation/Admin-Portal.svg';
import resourcesIcon from '../../assets/images/pods/navigation/Resources.svg';
import TokenManager from '../../service/tokens';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import {
  Button,
  CircularProgress,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  select: {
    '& .MuiSelect-selectMenu': {
      fontFamily: `'Archivo Narrow' !important`,
      fontWeight: '400 !important',
    },
    '& .MuiInput-underline::before': {
      borderBottom: '1px solid rgba(255, 255, 255, 0.42)',
    },
    '& .MuiInput-underline::after': {
      borderBottom: '1px solid rgba(255, 255, 255, 1) !important',
    },
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
  },
  menuButton: {
    float: 'left',
    backgroundColor: 'transparent',
    maxHeight: 36,
  },
  headerLabel: {
    color: 'white',
    display: 'inline-block',
    paddingLeft: 110,
    lineHeight: '38px',
    fontSize: '20px',
  },
  listText: {
    '& .MuiTypography-body1': {
      fontFamily: `'Archivo Narrow' !important`,
      fontWeight: '600 !important',
    },
  },
}));

const languages = [
  { key: 'en', value: 'English' },
  { key: 'es', value: 'Spanish' },
  { key: 'pt', value: 'Portugese' },
  { key: 'it', value: 'Italian' },
  { key: 'fr', value: 'French' },
  { key: 'de', value: 'German' },
  { key: 'zh', value: 'Chinese' },
];

const languageItems = () => {
  const items = [];
  items.push(
    <MenuItem key={'null'} value={null}>
      <em>Language</em>
    </MenuItem>
  );
  languages.forEach((l) =>
    items.push(
      <MenuItem key={l.key} value={l.key}>
        {l.value}
      </MenuItem>
    )
  );
  return items;
};

const LeftNavigation = (props) => {
  const classes = useStyles();
  const { history } = props;
  const [context, dispatch] = useContext(StoreContext);
  const { me } = context;
  const { pathname } = props.history.location;
  const [adminLink, setAdminLink] = useState(null);
  const [menuActive, setMenuActive] = useState(false);
  const [language, setLanguage] = useState('en');
  const [pods, setPods] = useState([]);
  const [loadComplete, setLoadComplete] = useState(false);
  const { t, i18n } = useTranslation();
  const [profiles] = useState([]);
  console.log('context', context);
  console.log('profiles', profiles);
  const location = useLocation();
  const activeMenu = location.pathname?.split('/')[1];

  const handleLogout = async () => {
    await axios
      .post(`${rootUrl}/api/logout`, {}, { withCredentials: true })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          logout(dispatch);
          history.push('/');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const changeLanguage = (language) => {
  //   i18n.changeLanguage(language);
  // };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const tm = TokenManager.getInstance();
    tm.addToken({ id: 'my-pods', source });
    axios
      .post(
        `${rootUrl}/api/get-my-pods`,
        { isSpecific: true },
        { withCredentials: true, cancelToken: source.token }
      )
      .then((results) => {
        //success
        // move remote pod to top
        const pods = results.data.filter((p) => !p.isRemote);
        pods.unshift(results.data.filter((p) => p.isRemote));
        setPods(pods.flat());
        setLoadComplete(true);
      });
  }, []);

  const createAdminLink = () => {
    setAdminLink(
      <ListItem
        button
        component={Link}
        to={`/admin-portal`}
        className={`${styles.menuHeader} ${
          activeMenu === 'admin-portal' ? styles.activeMenu : null
        } `}
      >
        <ListItemIcon className={styles.listIconWrapper}>
          <img src={adminIcon} alt="Admin Portal" />
        </ListItemIcon>
        <ListItemText
          primary={t('Admin Portal')}
          className={`${styles.listTextWrapper} ${classes.listText}`}
        />
      </ListItem>
    );
  };

  const BuildMenu = (language) => {
    //TODO build menu based on user info
    const tm = TokenManager.getInstance();
    return (
      <div className={styles.root}>
        <List component="nav" className={styles.listRoot}>
          <ListItem
            button
            component={Link}
            onClick={() => {
              if (tm.canelTokens) {
                tm.canelTokens();
              }
            }}
            to={`/pods/${pods.length ? pods[0].id : ''}`}
            className={`${styles.menuHeader} ${
              activeMenu === 'pods' ? styles.activeMenu : null
            } `}
          >
            <ListItemIcon className={styles.listIconWrapper}>
              <img src={podsIcon} alt="Pods" />
            </ListItemIcon>
            <ListItemText
              primary={t('My Pods')}
              className={`${styles.listTextWrapper} ${classes.listText}`}
            />
          </ListItem>
          <Collapse in={activeMenu === 'pods'} timeout="auto" unmountOnExit>
            {activeMenu === 'pods' && (pods.length || !loadComplete) && (
              <List dense={true} className={styles.menuSubHeaderWrapper}>
                {pods.map((pod) => (
                  <ListItem
                    button
                    component={Link}
                    className={styles.podItem}
                    key={pod.id}
                    onClick={() => {
                      if (tm.canelTokens) {
                        tm.canelTokens();
                      }
                    }}
                    to={{ pathname: `/pods/${pod.id}` }}
                  >
                    <ListItemText primary={pod.name} />
                  </ListItem>
                ))}
                {!loadComplete && (
                  <CircularProgress className={styles.podsLoading} />
                )}
              </List>
            )}
          </Collapse>
          {adminLink}
          <ListItem
            button
            component={Link}
            to={`/profile/${context.me.id}`}
            onClick={() => {
              if (tm.canelTokens) {
                tm.canelTokens();
              }
            }}
            className={`${styles.menuHeader} ${
              activeMenu === 'profile' ? styles.activeMenu : null
            } `}
          >
            <ListItemIcon className={styles.listIconWrapper}>
              <img src={accountIcon} alt="My Account" />
            </ListItemIcon>
            <ListItemText
              primary={t('My Account')}
              className={`${styles.listTextWrapper} ${classes.listText}`}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/team"
            onClick={() => {
              if (tm.canelTokens) {
                tm.canelTokens();
              }
            }}
            className={`${styles.menuHeader} ${
              activeMenu === 'team' ? styles.activeMenu : null
            } `}
          >
            <ListItemIcon className={styles.listIconWrapper}>
              <img src={leadershipIcon} alt="Leadership Team" />
            </ListItemIcon>
            <ListItemText
              primary={t('Leadership Team')}
              className={`${styles.listTextWrapper} ${classes.listText}`}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/map"
            onClick={() => {
              if (tm.canelTokens) {
                tm.canelTokens();
              }
            }}
            className={`${styles.menuHeader} ${
              activeMenu === 'map' ? styles.activeMenu : null
            } `}
          >
            <ListItemIcon className={styles.listIconWrapper}>
              <img src={mapIcon} alt="View or Join Pods" />
            </ListItemIcon>
            <ListItemText
              primary={t('View or Join Pods')}
              className={`${styles.listTextWrapper} ${classes.listText}`}
            />
          </ListItem>
          <ListItem
            button
            component="a"
            href="https://www.500womenscientists.org/pods"
            target="_blank"
            className={`${styles.menuHeader}`}
          >
            <ListItemIcon className={styles.listIconWrapper}>
              <img src={resourcesIcon} alt="About Pods" />
            </ListItemIcon>
            <ListItemText
              primary={t('About Pods')}
              className={`${styles.listTextWrapper} ${classes.listText}`}
            />
          </ListItem>
        </List>
      </div>
    );
  };

  if (me && me.isAdmin && !adminLink) {
    createAdminLink();
  }

  return (
    <>
      <div className={styles.header}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.menuButton}
          onClick={() => setMenuActive(true)}
        >
          <i className="fas fa-bars fa-2x"></i>
        </Button>
        <Typography variant="body1" className={classes.headerLabel}>
          ORCA
        </Typography>
      </div>
      <nav
        className={`${styles.topnav} ${
          menuActive ? styles.topnavActive : null
        }`}
      >
        <div className={styles.banner}>
          <a
            className={styles.imageWrapper}
            style={{ marginLeft: 'auto' }}
            title="Go to 500womenscientists.org"
            target="blank"
            href="http://500womenscientists.org/"
          >
            <div className={styles.image}></div>
          </a>

          <header className={styles.title}>
            {t('Welcome, ')}
            {context.me.name}
          </header>
          <button className={styles.logout} onClick={handleLogout}>
            {t('Log Out')}
          </button>
        </div>
        <FormControl>
          <Select
            id="language-select"
            value={i18n?.language || language}
            displayEmpty
            className={`${styles.languagesSelect} ${classes.select}`}
            onChange={(e) => {
              setLanguage(e.target.value);
              //update language
              i18n.changeLanguage(e.target.value);
            }}
          >
            {languageItems()}
          </Select>
        </FormControl>
        {BuildMenu()}
        <div className={styles.linksWrapper}>
          {pathname !== '/search' &&
            pathname !== '/privacy' &&
            pathname !== '/frame' && (
              <div className={styles.privacyWrapper}>
                <a
                  className={styles.privacy}
                  style={{ marginLeft: 'auto' }}
                  target="blank"
                  href="http://500womenscientists.org/privacy-statement"
                >
                  {t('Privacy Policy')}
                </a>
              </div>
            )}
          <div className={styles.privacyWrapper}>
            <a
              className={styles.privacy}
              style={{ marginLeft: 'auto' }}
              target="blank"
              href="http://500womenscientists.org"
            >
              500 Women Scientists
            </a>
          </div>
          <div className={styles.privacyWrapper}>
            <a
              className={styles.privacy}
              style={{ marginLeft: 'auto' }}
              href="mailto:pods@500womenscientists.org"
              rel="noopener noreferrer"
            >
              {t('Contact Us')}
            </a>
          </div>
        </div>
        {/* <img src={LanguageIcon} alt="Select your language" />
        <select
          className={styles.languageSelect}
          onChange={(e) => changeLanguage(e.target.value)}
          value={i18n.language}
        >
          <option value="en">English</option>
          <option value="es">Spanish</option>
          <option value="pt">Portuguese</option>
          <option value="it">Italian</option>
          <option value="fr">French</option>
          <option value="de">German</option>
          <option value="zh">Chinese</option>
        </select> */}

        {/* {!authorized && (
          <div className={styles.auth}>
            {!context.me.authenticated && (
              <>
                <button
                  className={styles.signup}
                  onClick={() => {
                    history.push('/sign-up');
                  }}
                >
                  {t('Sign Up')}
                </button>

                <span className={styles.divider}></span>
              </>
            )}
            {context.me.authenticated ? (
              <button className={styles.login} onClick={handleLogout}>
                {t('Logout')}
              </button>
            ) : (
              <button
                className={styles.login}
                onClick={() => history.push('/login')}
              >
                {t('Login')}
              </button>
            )}
          </div>
        )}
        {authorized && (
          <Link className={styles.account} to={'/account'}>
            {t('My Account')}
          </Link>
        )} */}
        {/* <button
          className={isOpen ? styles.isOpen : styles.toggleButton}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className={styles.toggleButtonLine}></span>
          <span className={styles.toggleButtonLine}></span>
          <span className={styles.toggleButtonLine}></span>
        </button> */}
      </nav>
      {/* {isOpen && (
        <SideDrawer setIsOpen={setIsOpen} handleLogout={handleLogout} />
      )} */}
    </>
  );
};

export default withRouter(LeftNavigation);
