import React, { useState, useEffect, useContext, useRef } from 'react';
import { rootUrl } from '../../config';
// import { Snackbar } from '@material-ui/core';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import Goodbye from '../../components/Dialogs/Goodbye';
import axios from 'axios';
// import LandingSplashImage from '../../assets/images/gage/landing/SplashImage.png';
// import LandingSearch from '../../components/Gage/LandingSearch';
import StoreContext from '../../state/context/store';
import * as Plot from '@observablehq/plot';
import * as d3 from 'd3';

const GapChart = (props) => {
  const { t } = useTranslation();
  const [context, dispatch] = useContext(StoreContext);
  const { me } = context;
  const chartRef = useRef();
  const data = props.data;
  const dots = props.dots;
  const view = props.view;

  let tHeight = window.innerHeight - 400;
  let tWidth = window.innerWidth - 530;
  if (view === 'mobile') {
    tWidth = window.innerWidth;
    tHeight = window.innerHeight - 350;
  } else if (tWidth > 1000) {
    tWidth = 1000;
  }
  const [width, setWidth] = useState(tWidth);
  const [height, setHeight] = useState(tHeight);

  const hover = (tip, pos, text) => {
    const side_padding = 10;
    const vertical_padding = 5;
    const vertical_offset = 25;

    // Empty it out
    tip.selectAll('*').remove();

    // Append the text
    tip
      .style('text-anchor', 'middle')
      .style('pointer-events', 'none')
      .attr('transform', `translate(${pos[0]}, ${pos[1] + 7})`)
      .selectAll('text')
      .data(text)
      .join('text')
      .style('dominant-baseline', 'ideographic')
      .text((d) => d)
      .attr('y', (d, i) => (i - (text.length - 1)) * 20 - vertical_offset)
      .style('font-size', 11)
      // .style('text-anchor', (d, i) => (i === 0 ? 'middle' : 'inherit'))
      .style('font-weight', (d, i) => (i === 0 ? 'bold' : 'normal'))
      .style('fill', '#fff');

    const bbox = tip.node().getBBox();

    // Add a rectangle (as background)
    tip.append('polygon').attr('points', '-9,-21 9, -21, 0,-11');

    tip
      .append('rect')
      .attr('y', bbox.y - vertical_padding)
      .attr('x', bbox.x - side_padding)
      .attr('rx', '7')
      .attr('ry', '7')
      .attr('width', bbox.width + side_padding * 2)
      .attr('height', bbox.height + vertical_padding * 2)
      .style('fill', '#000')
      .lower();
  };

  const addTooltips = (chart, styles) => {
    const stroke_styles = { stroke: '', 'stroke-width': 0 };
    const fill_styles = { fill: '' };

    // Workaround if it's in a figure
    const type = d3.select(chart).node().tagName;
    let wrapper =
      type === 'FIGURE' ? d3.select(chart).select('svg') : d3.select(chart);

    // Workaround if there's a legend....
    const svgs = d3.select(chart).selectAll('svg');
    if (svgs.size() > 1) wrapper = d3.select([...svgs].pop());
    wrapper.style('overflow', 'visible'); // to avoid clipping at the edges

    // Set pointer events to visibleStroke if the fill is none (e.g., if its a line)
    wrapper.selectAll('path').each(function (data, index, nodes) {
      // For line charts, set the pointer events to be visible stroke
      if (
        d3.select(this).attr('fill') === null ||
        d3.select(this).attr('fill') === 'none'
      ) {
        d3.select(this).style('pointer-events', 'visibleStroke');
        // if (styles === undefined) styles = stroke_styles;
      }
    });

    if (styles === undefined) styles = fill_styles;

    const tip = wrapper
      .selectAll('.hover')
      .data([1])
      .join('g')
      .attr('class', 'hover')
      .style('pointer-events', 'none')
      .style('text-anchor', 'middle');

    // Add a unique id to the chart for styling
    const id = id_generator();

    // Add the event listeners
    d3.select(chart).classed(id, true); // using a class selector so that it doesn't overwrite the ID
    wrapper.selectAll('title').each(function () {
      // Get the text out of the title, set it as an attribute on the parent, and remove it
      const title = d3.select(this); // title element that we want to remove
      const parent = d3.select(this.parentNode); // visual mark on the screen
      const t = title.text();
      if (t) {
        parent.attr('__title', t).classed('has-title', true);
        title.remove();
      }
      // Mouse events
      parent
        .on('pointerenter pointermove', function (event) {
          const text = d3.select(this).attr('__title');
          const pointer = d3.pointer(event, wrapper.node());
          if (text) tip.call(hover, pointer, text.split('\n'));
          else tip.selectAll('*').remove();

          // Raise it
          d3.select(this).raise();
          // Keep within the parent horizontally
          const tipSize = tip.node().getBBox();
          if (pointer[0] + tipSize.x < 0)
            tip.attr(
              'transform',
              `translate(${tipSize.width / 2}, ${pointer[1] + 7})`
            );
          else if (pointer[0] + tipSize.width / 2 > wrapper.attr('width'))
            tip.attr(
              'transform',
              `translate(${wrapper.attr('width') - tipSize.width / 2}, ${
                pointer[1] + 7
              })`
            );
        })
        .on('pointerout', function (event) {
          tip.selectAll('*').remove();
          // Lower it!
          d3.select(this).lower();
        });
    });

    // Remove the tip if you tap on the wrapper (for mobile)
    wrapper.on('touchstart', () => tip.selectAll('*').remove());

    return chart;
  };

  const id_generator = () => {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return 'a' + S4() + S4();
  };

  const updateWindowDimensions = () => {
    let width = window.innerWidth - 400;
    let height = window.innerHeight - 450;
    if (view === 'mobile') {
      width = window.innerWidth;
      height = window.innerHeight - 350;
    } else if (width > 1000) {
      width = 1000;
    }
    setWidth(width);
    setHeight(height);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);
    // cleanup this component
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  });

  let chartData = {
    height: height,
    width: width,
    style: {
      fontSize: 12,
    },
    x: {
      label: 'Honoraria Value (Hourly Rate, USD)',
      labelAnchor: 'center',
      labelOffset: 60,
      tickFormat: d3.format('$,'),
    },
    y: {
      axis: null,
    },
    fy: {
      padding: 0,
    },
    facet: {
      data: data,
      y: 'Honoraria Type',
      marginLeft: 110,
      marginBottom: 25,
      label: '',
    },
    grid: true,
    marks: [
      Plot.ruleX([0]),
      Plot.areaY(data, {
        y: (d) => d.weight,
        y1: (d) => -d.weight,
        x: 'value',
        fill: '#f5b342',
      }),
      Plot.lineY(data, {
        y: (d) => d.weight,
        x: 'value',
        stroke: '#000',
        strokeOpacity: 1,
        z: 'i',
      }),
      Plot.lineY(data, {
        y: (d) => -d.weight,
        x: 'value',
        stroke: '#000',
        strokeOpacity: 1,
        z: 'i',
      }),
      Plot.dot(data, {
        y: (d) => d.weight,
        x: 'value',
        z: 'i',
        strokeOpacity: 0,
        title: (d) =>
          `HONORARIA VALUE\nHonoraria value: ${d3.format('$,')(
            d.value
          )}\nRespondents: ${d.resp}`,
      }),
      Plot.dot(data, {
        y: (d) => 0,
        x: 'value',
        z: 'i',
        r: 10,
        // strokeOpacity: (d) => dots.includes(d['Honoraria Type']) ? 1 : 0,
        strokeOpacity: 0,
        title: (d) =>
          `HONORARIA VALUE\nHonoraria value: ${d3.format('$,')(
            d.value
          )}\nRespondents: ${d.resp}`,
      }),
      Plot.dot(data, {
        y: (d) => -d.weight,
        x: 'value',
        z: 'i',
        strokeOpacity: 0,
        title: (d) =>
          `HONORARIA VALUE\nHonoraria value: ${d3.format('$,')(
            d.value
          )}\nRespondents: ${d.resp}`,
      }),
    ],
  };

  if (dots.length > 0) {
    chartData.marks.push(
      Plot.dot(data, {
        y: (d) => 0,
        x: 'value',
        z: 'i',
        r: 10,
        fill: '#f5b342',
        fillOpacity: (d) => (dots.includes(d['Honoraria Type']) ? 1 : 0),
        stroke: '#000',
        strokeOpacity: (d) => (dots.includes(d['Honoraria Type']) ? 1 : 0),
        title: (d) =>
          `HONORARIA VALUE\nHonoraria value: ${d3.format('$,')(
            d.value
          )}\nRespondents: ${d.resp}`,
      })
    );
  }

  const adjust = () => {
    const labels = d3.selectAll('text').nodes();
    labels.forEach((label) => {
      const edge =
        parseInt(label.getAttribute('x')) + label.getBoundingClientRect().width;
      if (edge > tWidth) {
        label.parentElement.setAttribute('text-anchor', 'end');
        label.setAttribute('dx', 0);
      }
    });
  };

  adjust();

  useEffect(() => {
    if (data === undefined) return;

    const chart = addTooltips(Plot.plot(chartData));
    chartRef.current.append(chart);
    return () => chart.remove();
  }, [data, width]);

  return <div className={styles.chart} id="chart" ref={chartRef}></div>;
};

export default GapChart;
