// @flow
import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import styles from './index.module.scss';
import StoreContext from '../../../state/context/store';
import { logout } from '../../../state/actions/me';
import { rootUrl } from '../../../config';
import GageIcon from '../../../assets/images/gage/header/GageLogo.svg';
import GageIconSmall from '../../../assets/images/gage/header/GageLogoAlt.png';
import ProfileIconPlaceholder from '../../../assets/images/gage/header/ProfileIconPlaceholder.svg';
import ProfileIconBorder from '../../../assets/images/gage/header/ProfileIconBorder.svg';
import LogoutIcon from '../../../assets/images/gage/header/LogoutIcon.svg';
import { LayoutContext } from '../../../state/context/layout';
import { FormControl, MenuItem, Select } from '@material-ui/core';

const Header = (props) => {
  const { history } = props;
  const [context, dispatch] = useContext(StoreContext);
  const { phoneActive, tabletActive } = useContext(LayoutContext);
  const { t, i18n } = useTranslation();

  //attempt to retrieve language from session
  useEffect(() => {
    const storedLanguage = sessionStorage.getItem('language');
    if (storedLanguage !== i18n.language) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]); // eslint-disable-line

  const handleLogout = async () => {
    await axios
      .post(`${rootUrl}/api/logout`, {}, { withCredentials: true })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          logout(dispatch);
          history.push('/');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    sessionStorage.setItem('language', language);
  };

  return (
    <>
      <nav className={styles.topnav}>
        {!phoneActive && !tabletActive ? (
          <>
            <img
              src={GageIcon}
              alt="Gage."
              className={styles.gageLogo}
              useMap="#logo-map"
            />
            <map name="logo-map">
              <area
                shape="rect"
                coords="0,0,220,115"
                href="/"
                alt="Gage Home"
              />
              <area
                shape="rect"
                coords="220,0,350,115"
                href="//500womenscientists.org"
                target="_blank"
                alt="500ws Home"
              />
            </map>
          </>
        ) : (
          <img
            src={GageIconSmall}
            alt="Gage."
            className={styles.gageLogoSmall}
            onClick={() => {
              history.push('/');
            }}
          />
        )}
        {!phoneActive &&
          !tabletActive &&
          process.env.NODE_ENV !== 'production' && (
            <div className={styles.testingFeedback}>
              <a
                href="https://airtable.com/shrmQ2zIf657G10ue"
                target="_blank"
                rel="noopener noreferrer"
              >
                Testing Feedback
              </a>
            </div>
          )}
        <FormControl className={styles.languageSelect}>
          <Select
            onChange={(e) => changeLanguage(e.target.value)}
            value={i18n.language}
            defaultValue={'en'}
            MenuProps={{
              classes: { paper: 'languageMenu' },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="es">Español</MenuItem>
            <MenuItem value="pt">Português</MenuItem>
            <MenuItem value="it">Italiano</MenuItem>
            <MenuItem value="fr">Français</MenuItem>
            <MenuItem value="de">Deutsch</MenuItem>
            <MenuItem value="zh">中国</MenuItem>
          </Select>
        </FormControl>

        <div className={styles.auth}>
          {context.me.authenticated && !phoneActive && !tabletActive && (
            <div className={styles.name}>
              {t('Hi')} {context.me.name}
            </div>
          )}
          {context.me.authenticated && (
            <>
              <div
                className={styles.profileIconWrapper}
                style={
                  context.me.profilePictureThumb && {
                    backgroundImage: `url(https://500ws-profiles.s3-us-west-2.amazonaws.com/${context.me.profilePictureThumb}`,
                  }
                }
              >
                <img
                  src={
                    context.me.profilePictureThumb
                      ? ProfileIconBorder
                      : ProfileIconPlaceholder
                  }
                  alt={context.me.name}
                  className={styles.profileIcon}
                  onClick={() => {
                    history.push(`/profile/${context.me.id}`);
                  }}
                ></img>
              </div>
              <span className={styles.divider}></span>
            </>
          )}
          {context.me.authenticated ? (
            <button
              className={styles.login}
              onClick={handleLogout}
              style={{ backgroundImage: `url(${LogoutIcon})` }}
            >
              {t('Logout')}
            </button>
          ) : (
            <button
              className={styles.login}
              onClick={() => history.push('/login')}
            >
              {t('Login')}
            </button>
          )}
        </div>
      </nav>
    </>
  );
};

export default withRouter(Header);
