import React, { useState } from 'react';
import axios from 'axios';
import { rootUrl } from '../../config';
import { useTranslation } from 'react-i18next';
import styles from './popup.module.scss';
//import Input from '@material-ui/core/TextField';
import {
  Button,
  Grid,
  Typography,
  IconButton,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import '@fortawesome/fontawesome-free';
import 'components-font-awesome';
import { Popup } from 'react-mapbox-gl';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  active: {
    color: '#6e3694',
    cursor: 'pointer',
    width: 30,
    fontSize: '1.5em',
  },
  popupWrapper: {
    '& .mapboxgl-popup-content': {
      boxShadow: '0px 25px 42px 0 rgba(44, 0, 64, .15)',
      borderRadius: 0,
    },
  },
  nopointer: {
    cursor: 'default',
  },
  link: {
    paddingLeft: 8,
    maxWidth: 210,
    height: 20,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    overflowWrap: 'normal',
  },
  bottom: {
    marginBottom: 15,
  },
}));

// pod popup
const Podup = (props) => {
  const {
    feature,
    pod,
    isPopup,
    setPopup,
    coordinators,
    updateMap,
    myPods,
    isIframe,
    setSnacking,
    setMessage,
    setSeverity,
    joinRemote,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [leaveOpen, setLeaveOpen] = useState(false);
  const [joinOpen, setJoinOpen] = useState(false);

  const closePopup = () => {
    setPopup(false);
  };

  const joinPod = (e) => {
    e.stopPropagation();
    setJoinOpen(true);
  };

  const confirmJoinPod = () => {
    setJoinOpen(false);
    // skip to main map feature for remotes
    if (pod && pod.isRemote) {
      joinRemote();
      return;
    }

    axios
      .post(
        `${rootUrl}/api/pod/join`,
        {
          podId: feature.properties.id,
          status: 'pending',
        },
        { withCredentials: true }
      )
      .then((results) => {
        //success
        // console.log(results);
        setMessage(
          `${t('You have joined Pod')} '${feature.properties.name}'. ${t(
            'You will be notified once approved'
          )}.`
        );
        setSeverity('success');
        setSnacking(true);
        setPopup(false);
        updateMap(feature.properties.id, '+');
      });
  };

  const leavePod = (e) => {
    e.stopPropagation();
    setLeaveOpen(true);
  };

  const confirmLeavePod = () => {
    setLeaveOpen(false);
    axios
      .post(
        `${rootUrl}/api/pod/leave`,
        {
          podId: feature.properties.id,
        },
        { withCredentials: true }
      )
      .then((results) => {
        //success
        // console.log(results);
        setMessage(
          `You are no longer a member of Pod '${feature.properties.name}'.`
        );
        setSeverity('success');
        setSnacking(true);
        setPopup(false);
        updateMap(feature.properties.id, '-');
      });
  };

  return (
    <>
      {isPopup && (
        <Popup
          coordinates={feature.geometry.coordinates}
          key={feature.properties.id}
          onClose={closePopup}
          className={`${styles.wrapper} ${classes.popupWrapper}`}
          closeButton={true}
          closeOnClick={false}
          offset={{
            bottom: [0, -38],
          }}
        >
          <div className={`${styles.popup} `}>
            <IconButton className={styles.close} onClick={closePopup}>
              <i className="fas fa-times" />
            </IconButton>
            <Typography variant="h4" className={styles.title}>
              {t(`${feature.properties.name} Pod`)}
            </Typography>
            <Typography variant="h5" className={styles.contact}>
              {t('Contact') + ':'}
            </Typography>
            {coordinators.length > 0 && (
              <div>
                {coordinators.map((c, n) => (
                  <p key={c.podProfileId + n} className={styles.contactName}>
                    <a href={`mailto:${c.podProfile.user?.email}`}>
                      {c.podProfile.name}
                    </a>
                  </p>
                ))}
              </div>
            )}
            {feature.properties.isNew && (
              <Typography
                variant="body1"
                className={`${styles.darkPurple} ${classes.bottom}`}
              >
                {t('This pod has not yet been approved. Please contact ')}
                <a href="mailto:pods@500womenscientists.org">
                  pods@500womenscientists.org{' '}
                </a>
                {' for additional info.'}
              </Typography>
            )}
            {feature.properties.isNew && (
              <Typography variant="body1" className={styles.darkPurple}>
                {t(
                  'If you join (or have joined), you will be notified once the pod has been approved and assigned a coordinator.'
                )}
              </Typography>
            )}
            {coordinators.length === 0 && !feature.properties.isNew && (
              <Typography
                variant="body1"
                className={`${styles.darkPurple} ${classes.bottom}`}
              >
                {t('This pod does not have an active coordinator.')}
              </Typography>
            )}
            {coordinators.length === 0 && !feature.properties.isNew && (
              <Typography variant="body1" className={styles.darkPurple}>
                {t(
                  'If you join (or have joined), you will be notified once the pod has a coordinator. Or you can volunteer as coordinator, if you wish!'
                )}
              </Typography>
            )}
            {coordinators.length > 0 && !feature.properties.isNew && (
              <Grid container className={styles.grid} spacing={0}>
                {feature.properties.facebook && (
                  <Grid item xs={12}>
                    <a
                      href={
                        feature.properties.facebook.indexOf('http') > -1
                          ? feature.properties.facebook
                          : 'http://' + feature.properties.facebook
                      }
                      className={classes.icon}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className={`fab fa-facebook fa-1x ${classes.active}`}
                      />
                      <Typography className={classes.link} variant="body1">
                        {feature.properties.facebook}
                      </Typography>
                    </a>
                  </Grid>
                )}

                {feature.properties.instagram && (
                  <Grid item xs={12}>
                    <a
                      href={
                        feature.properties.instagram.indexOf('http') > -1
                          ? feature.properties.instagram
                          : 'http://' + feature.properties.instagram
                      }
                      className={classes.icon}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className={`fab fa-instagram fa-1x ${classes.active}`}
                      />
                      <Typography className={classes.link} variant="body1">
                        {feature.properties.instagram}
                      </Typography>
                    </a>
                  </Grid>
                )}

                {feature.properties.twitter && (
                  <Grid item xs={12}>
                    <a
                      href={
                        feature.properties.twitter.indexOf('http') > -1
                          ? feature.properties.twitter
                          : 'http://' + feature.properties.twitter
                      }
                      className={classes.icon}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className={`fab fa-twitter-square fa-1x ${classes.active}`}
                      />
                      <Typography className={classes.link} variant="body1">
                        {feature.properties.twitter}
                      </Typography>
                    </a>
                  </Grid>
                )}

                {feature.properties.website && (
                  <Grid item xs={12}>
                    <a
                      href={
                        feature.properties.website.indexOf('http') > -1
                          ? feature.properties.website
                          : 'http://' + feature.properties.website
                      }
                      className={classes.icon}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className={`fas fa-link fa-1x ${classes.active}`} />
                      <Typography className={classes.link} variant="body1">
                        {feature.properties.website}
                      </Typography>
                    </a>
                  </Grid>
                )}
              </Grid>
            )}
            {!isIframe &&
              (!myPods || !myPods.length || myPods.indexOf(pod.id) < 0) && (
                <Button className={styles.purpleButton} onClick={joinPod}>
                  {t('Join Pod')}
                </Button>
              )}
            {!isIframe &&
              myPods &&
              myPods.length > 0 &&
              myPods.indexOf(pod.id) > -1 && (
                <Button className={styles.deleteButton} onClick={leavePod}>
                  {t('Leave Pod')}
                </Button>
              )}
          </div>
        </Popup>
      )}
      <Dialog
        open={joinOpen}
        onClose={() => setJoinOpen(false)}
        className={styles.modal}
        aria-labelledby="join-popup-title"
        aria-describedby="join-popup-description"
      >
        <DialogTitle className={styles.modalTitle} id="join-popup-title">
          {t('Join this Pod?')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="join-popup-description"
            className={styles.modalContent}
          >
            {t('You are requesting to join the')}{' '}
            <strong>{pod.name} Pod</strong>.
            {t(
              'Once approved, you will receive a notification and be able to access this Pod from the Pods menu.'
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={`${styles.cancelButton} ${styles.buttonPadding}`}
            onClick={() => setJoinOpen(false)}
            color="secondary"
          >
            {t('Cancel')}
          </Button>
          <Button
            className={`${styles.purpleButton} ${styles.buttonPadding}`}
            onClick={confirmJoinPod}
            color="primary"
            autoFocus
          >
            {t('Join')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={leaveOpen}
        onClose={() => setLeaveOpen(false)}
        className={styles.modal}
        aria-labelledby="leave-popup-title"
        aria-describedby="leave-popup-description"
      >
        <DialogTitle id="leave-popup-title" className={styles.modalTitle}>
          {t('Are you sure you want to leave this Pod?')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="leave-popup-description"
            className={styles.modalContent}
          >
            {t('This will completely remove you from')}{' '}
            <strong>{pod.name}</strong>.
            {t(
              'Once removed, you can always rejoin this pod if you change your mind'
            )}
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={`${styles.cancelButton} ${styles.buttonPadding}`}
            onClick={() => setLeaveOpen(false)}
            color="secondary"
          >
            {t('Cancel')}
          </Button>
          <Button
            className={`${styles.deleteButton} ${styles.buttonPadding}`}
            onClick={confirmLeavePod}
            color="primary"
            autoFocus
          >
            {t('Leave')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Podup;
