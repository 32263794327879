import React, { useContext } from 'react';
import styles from './index.module.scss';
// import { useTranslation } from 'react-i18next';
import StoreContext from '../../state/context/store';

const GapTableMobile = (props) => {
  // const { t } = useTranslation();
  // const [context, dispatch] = useContext(StoreContext);
  // const { me } = context;
  const data = props.data;
  return (
    <div className={styles.tableContainerMobile}>
      {data.map((item, i) => (
        <div key={item.id} className={styles.tableItem}>
          <div className={styles.tableRow}>
            <div>Entry {i + 1}</div>
            <div>{item.talkType}</div>
          </div>
          <div className={styles.tableRow}>
            <div>Honoraria value</div>
            <div>{item.honorariaValue} USD/hour</div>
          </div>
          <div className={styles.tableRow}>
            <div>Year</div>
            <div>{item.year}</div>
          </div>
          <div className={styles.tableRow}>
            <div>Country</div>
            <div>{item.countryOfSpeakingEngagement}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GapTableMobile;
