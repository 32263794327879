import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { rootUrl } from '../../config';
import { useTranslation } from 'react-i18next';
import styles from './new-pod.module.scss';
//import Input from '@material-ui/core/TextField';
import { Button, Paper, TextField, Typography } from '@material-ui/core';
import '@fortawesome/fontawesome-free';
import 'components-font-awesome';

// pod popup
const NewPod = (props) => {
  const {
    location,
    setLocation,
    setSnacking,
    setMessage,
    setSeverity,
    setAdding,
    getData,
  } = props;
  const { t } = useTranslation();
  const [lookup, setLookup] = useState(null);
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  // const [description, setDescription] = useState('');
  // const webRef = createRef(null);

  useEffect(() => {
    if (location.lng) {
      fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${location.lng},${location.lat}.json?access_token=${process.env.REACT_APP_PODS_MAPBOX_TOKEN}&language=en-US&limit=1`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/vnd.geo+json',
          },
        }
      ).then(async function (response) {
        if (response.status === 200) {
          const json = await response.json();
          if (json?.features.length) {
            const feat = json?.features[0];
            setLookup(feat);
            const country = feat.context.find((c) =>
              c.id.startsWith('country')
            );
            setCountry(country?.text);
            const region = feat.context.find((c) => c.id.startsWith('region'));
            setState(region?.text);
            const place = feat.context.find((c) => c.id.startsWith('place'));
            setCity(place?.text);
            setName(
              place?.text ? `${place?.text}, ${region?.text}` : region?.text
            );
          } else {
            setLookup(null);
          }
        }
      });
    }
  }, [location]);

  const cancelPod = () => {
    setLocation(null);
    setLookup(null);
    setCountry('');
    setState('');
    setCity('');
    setName('');
    setAdding(null);
  };

  const finishPod = () => {
    //save event, clear coords
    axios
      .post(
        `${rootUrl}/api/pod/create`,
        {
          name,
          city,
          state,
          country,
          // description,
          location: JSON.stringify(lookup.geometry),
        },
        { withCredentials: true }
      )
      .then((results) => {
        if (results.status === 200) {
          setMessage(
            'Pod created. Refreshing map data. You will be notified once the pod has been approved'
          );
          setSeverity('success');
          setSnacking(true);
          getData();
          cancelPod();
        } else {
          setMessage(results.data.toString());
          setSeverity('error');
          setSnacking(true);
        }
      })
      .catch((e) => {
        setMessage(e.message);
        setSeverity('error');
        setSnacking(true);
      });
  };

  return (
    <>
      <Paper elevation={3} className={styles.wrapper}>
        <div className={styles.labelWrapper}>
          <Typography variant="body1" className={styles.label}>
            {t('Name')}
          </Typography>
          <TextField
            className={styles.input}
            value={name}
            variant="outlined"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className={styles.labelWrapper}>
          <Typography variant="body1" className={styles.label}>
            {t('City')}
          </Typography>
          <TextField
            readOnly
            className={styles.input}
            value={city}
            variant="outlined"
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
        <div className={styles.labelWrapper}>
          <Typography variant="body1" className={styles.label}>
            {t('State')}
          </Typography>
          <TextField
            readOnly
            className={styles.input}
            value={state}
            variant="outlined"
            onChange={(e) => setState(e.target.value)}
          />
        </div>
        <div className={styles.labelWrapper}>
          <Typography variant="body1" className={styles.label}>
            {t('Country')}
          </Typography>
          <TextField
            readOnly
            className={styles.input}
            value={country}
            variant="outlined"
            onChange={(e) => setCountry(e.target.value)}
          />
        </div>

        {/* <TextField
          rows={4}
          className={styles.input}
          multiline={true}
          label="Description"
          aria-label="description"
          value={description}
          variant="outlined"
          onChange={(e) => setDescription(e.target.value)}
        /> */}
        <div className={styles.buttonWrapper}>
          <Button
            disabled={lookup === null || name === ''}
            className={`${styles.purpleButton} ${styles.marginTop} ${styles.leftIndent}`}
            onClick={finishPod}
          >
            {t('Request a new Pod')}
          </Button>
          <Button
            className={`${styles.cancelButton} ${styles.marginTop}`}
            onClick={cancelPod}
          >
            {t('Cancel')}
          </Button>
        </div>
      </Paper>
    </>
  );
};

export default NewPod;
