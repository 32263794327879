import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import axios from 'axios';
import styles from './index.module.scss';
import Grid from '@material-ui/core/Grid';
import { rootUrl } from '../../../config';
import {
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  Snackbar,
  TextField,
  Typography,
  Link,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Multiselect from '../../Multiselect';

const multiSelectOptions = [
  { label: 'Advocacy opportunities', value: 'advocacy_opportunities' },
  { label: 'Outreach', value: 'outreach' },
  { label: 'Mentorship', value: 'mentorship' },
  { label: 'Networking', value: 'networking' },
  {
    label: 'Friendship/being part of a community',
    value: 'friendship_community',
  },
  { label: 'JEDI literacy', value: 'jedi' },
  { label: 'Looking for a safe space', value: 'safe_space' },
  {
    label: 'Professional skills development',
    value: 'professional_development',
  },
];

const useStyles = makeStyles((theme) => ({
  labelWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  confirmWrapper: {
    marginTop: 20,
  },
  account: {
    flex: 1,
  },
  inputWrapper: {
    width: 400,
    padding: '15px 0',
  },
  input: {
    width: '100%',
  },
  podMembership: {},
  podMembershipItem: {
    display: 'flex',
    flexDirection: 'colummn',
    maxWidth: 400,
    paddingTop: 5,
    paddingBottom: 5,
  },
  podName: {
    flex: 1,
    color: '#2c0040',
    fontWeight: 400,
  },
  podLeave: {},
  memberHeader: {
    paddingBottom: 5,
    fontWeight: 800,
  },
  memberWrapper: {
    marginTop: 10,
  },
  dialogWrapper: {
    '& .MuiDialog-paper': {
      padding: '40px 40px 10px 40px',
    },
  },
}));
Modal.setAppElement('#root');

const QuickProfile = (props) => {
  const { edit, account, name } = props;
  const { t } = useTranslation();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [leaveOpen, setLeaveOpen] = useState(false);
  const [pod2Leave, setLeavePod] = useState(null);
  const [nameEditable, setNameEditable] = useState(false);
  //const [emailEditable, setEmailEditable] = useState(false);
  const [passwordEditable, setPasswordEditable] = useState(false);
  const [aboutEditable, setAboutEditable] = useState(false);
  const [reasonEditable, setReasonEditable] = useState(false);
  const [fullNameError, setFullNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  // const [reasonError, setReasonError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [formName, setFormName] = useState('');
  const [formPassword, setFormPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formReason, setFormReason] = useState('');
  const [formAbout, setFormAbout] = useState('');
  // const [formIsCoordinator, setFormIsCoordinator] = useState(null);
  const [pods, setPods] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isDirty, setDirty] = useState(false);
  const [once, setOnce] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('setSeverity');
  const classes = useStyles();
  const emailRef = useRef({ current: null });
  const nameRef = useRef({ current: null });
  const passwordRef = useRef({ current: null });
  const aboutRef = useRef({ current: null });
  // const [, updateState] = React.useState();
  // const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    if ((account.name || account.email) && (!formName || !formEmail)) {
      // setup form
      setFormName(name);
      //setFormPassword('something');
      setFormEmail(account.email);
      const reasons = account.reasons?.map((r) => {
        return {
          label: multiSelectOptions.find((o) => o.value === r)?.label,
          value: r,
          key: r,
        };
      });
      setFormReason(reasons);
      setFormAbout(account.about);
      // setFormIsCoordinator(account.beCoordinator);
    }

    if (account.id && !once) {
      setOnce(true);
      axios
        .post(
          `${rootUrl}/api/get-my-pods`,
          { id: account.id, isSpecific: true },
          { withCredentials: true }
        )
        .then((results) => {
          //success
          setPods(results.data);
          setLoading(false);
        });
    }
  }, [account, formEmail, formName, name, once]);

  const cancel = () => {
    //reset form
    setDirty(false);

    setFormName(account.name);
    setFormEmail(account.email);
    setFormAbout(account.about);
    // setFormIsCoordinator(account.beCoordinator);
    const reasons = account.reasons?.map((r) => {
      return {
        label: multiSelectOptions.find((o) => o.value === r)?.label,
        value: r,
        key: r,
      };
    });

    setFormReason(reasons);

    clearEdits();
  };

  const clearEdits = () => {
    setNameEditable(false);
    // setEmailEditable(false);
    setPasswordEditable(false);
    setAboutEditable(false);
    setReasonEditable(false);
  };

  const validate = () => {
    let valid = true;
    if (!formName) {
      valid = false;
      setFullNameError('Name is a required field!');
    } else {
      setFullNameError('');
    }

    if (!formEmail) {
      valid = false;
      setEmailError('Name is a required field!');
    } else if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        formEmail
      )
    ) {
      valid = false;
      setEmailError("Invalid email. Must match 'jane.doe@site.com'.");
    } else {
      setEmailError('');
    }

    if (formPassword && formPassword.length < 8) {
      valid = false;
      setPasswordError('Password must be > 8 characters!');
    } else if (formPassword !== confirmPassword) {
      valid = false;
      setPasswordError('Passwords must match!');
    } else {
      setPasswordError('');
    }
    return valid;
  };

  const saveChanges = () => {
    const calls = [];
    if (validate()) {
      if (account) {
        //set user profile changes
        let data = {
          id: account.id,
          userId: account.id,
          reasons: formReason?.map((r) => r.value) || null,
          about: formAbout,
          // beCoordinator: formIsCoordinator ? formIsCoordinator : false,
          name: formName,
          email: formEmail ? formEmail.trim() : formEmail,
        };

        if (formPassword) {
          data.password = formPassword;
        }

        calls.push(
          axios.patch(`${rootUrl}/api/podProfile/${account.id}`, data, {
            withCredentials: true,
          })
        );
      }

      if (calls.length) {
        axios
          .all(calls)
          .then((results) => {
            const profileUpdate = results[results.length - 1];

            if (
              profileUpdate.status === 200 &&
              (!profileUpdate.data || !profileUpdate.data.fail)
            ) {
              //update data
              setPods(pods);
              //update form
              clearEdits();
              //inform user
              setMessage(t('Saved'));
              setSeverity('success');
              setOpen(true);
              setDirty(false);
            } else if (profileUpdate.data.fail) {
              if (profileUpdate.data.field === 'email') {
                setEmailError(profileUpdate.data.message);
              }
              setMessage('Error saving changes');
              setSeverity('error');
              setOpen(true);
            }
          })
          .catch((err) => {
            setMessage('Error saving changes');
            setSeverity('error');
            setOpen(true);
          });
      } else {
        setMessage('No changes detected.');
        setSeverity('info');
        setOpen(true);
        setDirty(false);
      }
    }
  };

  const leavePod = () => {
    axios
      .post(
        `${rootUrl}/api/pod/leave`,
        {
          podId: pod2Leave.id,
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 200) {
          const temp = [...pods];
          setPods(temp.filter((t) => t.id !== pod2Leave.id));
          setMessage('You are no longer a member of ' + pod2Leave.name + '.');
          setSeverity('success');
          setOpen(true);
          setLeavePod(null);
          setLeaveOpen(false);
        }
      });
  };

  const deleteAccount = async () => {
    await axios
      .delete(`${rootUrl}/api/pods/me/${account.id}`, { withCredentials: true })
      .catch((error) => {
        console.log('Error on /api/pods/me: ', error);
      });

    window.location.href = '/';
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={styles.root}>
      <Paper elevation={1} className={`${classes.paper} ${styles.paper}`}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <div className={styles.header}>
              <Typography variant="h6">{t('My Account')}</Typography>
            </div>
          </Grid>
          <Grid item xs={9}>
            {isDirty && (
              <div className={styles.toolbar}>
                <Button
                  className={`${styles.purpleButton} ${styles.leftIndent}`}
                  onClick={saveChanges}
                  disabled={!isDirty}
                >
                  {t('Save Changes')}
                </Button>
                <Button className={styles.cancelButton} onClick={cancel}>
                  {t('Cancel')}
                </Button>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <div className={classes.inputWrapper}>
              <div className={classes.labelWrapper}>
                <Typography variant="body1" className={styles.label}>
                  {t('Name')}
                </Typography>
                <Link
                  variant="body2"
                  className={styles.link}
                  hidden={!edit}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setDirty(true);
                    setNameEditable(!nameEditable);
                    setTimeout(() => {
                      nameRef.current.children[0].children[0].focus();
                    });
                  }}
                >
                  {t('Change')}
                </Link>
              </div>

              <TextField
                id="outlined-size-small"
                variant="outlined"
                size="small"
                ref={nameRef}
                className={classes.input}
                disabled={!nameEditable}
                onChange={({ target }) => {
                  setDirty(true);
                  setFormName(target.value);
                }}
                value={formName}
                helperText={fullNameError}
                error={fullNameError.length > 1}
              />
            </div>
            <div className={classes.inputWrapper}>
              <div className={classes.labelWrapper}>
                <Typography variant="body1" className={styles.label}>
                  {t('Email')}
                </Typography>
                {/* <Link
                  variant="body2"
                  className={styles.link}
                  href="#"
                  hidden={!edit}
                  onClick={(e) => {
                    e.preventDefault();
                    setDirty(true);
                    setEmailEditable(!emailEditable);
                    setTimeout(() => {
                      emailRef.current.children[0].children[0].focus();
                    });
                  }}
                >
                  {t('Change')}
                </Link> */}
              </div>

              <TextField
                id="outlined-size-small"
                variant="outlined"
                size="small"
                ref={emailRef}
                className={classes.input}
                disabled={true}
                // disabled={!emailEditable}
                onChange={({ target }) => {
                  setDirty(true);
                  setFormEmail(target.value);
                }}
                value={formEmail}
                helperText={emailError}
                error={emailError.length > 1}
              />
            </div>
            <div className={classes.inputWrapper}>
              <div className={classes.labelWrapper}>
                <Typography variant="body1" className={styles.label}>
                  {passwordEditable ? t('New ') : ''}
                  {t('Password')}
                </Typography>
                <Link
                  variant="body2"
                  className={styles.link}
                  href="#"
                  hidden={!edit}
                  onClick={(e) => {
                    e.preventDefault();
                    setDirty(true);
                    setPasswordEditable(!passwordEditable);
                    setTimeout(() => {
                      passwordRef.current.children[0].children[0].focus();
                    });
                  }}
                >
                  {t('Change')}
                </Link>
              </div>

              <TextField
                id="outlined-size-small"
                type="password"
                ref={passwordRef}
                autoComplete="current-password"
                variant="outlined"
                size="small"
                onChange={({ target }) => {
                  setDirty(true);
                  setFormPassword(target.value);
                }}
                value={formPassword}
                className={classes.input}
                disabled={!passwordEditable}
                placeholder="**********"
                helperText={passwordError}
                error={passwordError.length > 1}
              />

              {passwordEditable && (
                <div
                  className={`${classes.labelWrapper} ${classes.confirmWrapper}`}
                >
                  <Typography variant="body1" className={styles.label}>
                    {t('Confirm Password')}
                  </Typography>
                </div>
              )}
              {passwordEditable && (
                <TextField
                  type="password"
                  autoComplete="current-password"
                  variant="outlined"
                  size="small"
                  onChange={({ target }) => {
                    setDirty(true);
                    setConfirmPassword(target.value);
                  }}
                  value={confirmPassword}
                  className={classes.input}
                />
              )}
            </div>

            <div className={classes.inputWrapper}>
              <div className={classes.labelWrapper}>
                <Typography variant="body1" className={styles.label}>
                  {t('About')}
                </Typography>
                <Link
                  variant="body2"
                  className={styles.link}
                  href="#"
                  hidden={!edit}
                  onClick={(e) => {
                    e.preventDefault();
                    setDirty(true);
                    setAboutEditable(!aboutEditable);
                    setTimeout(() => {
                      aboutRef.current.children[0].children[0].focus();
                    });
                  }}
                >
                  {t('Change')}
                </Link>
              </div>

              <TextField
                id="outlined-size-small"
                variant="outlined"
                size="small"
                multiline
                rows={4}
                ref={aboutRef}
                className={classes.input}
                disabled={!aboutEditable}
                onChange={({ target }) => {
                  setDirty(true);
                  setFormAbout(target.value);
                }}
                value={formAbout || ''}
              />
            </div>

            <div className={classes.inputWrapper}>
              <div className={classes.labelWrapper}>
                <Typography variant="body1" className={styles.label}>
                  {t('Reason(s) for Joining')}
                </Typography>
                <Link
                  variant="body2"
                  className={styles.link}
                  href="#"
                  hidden={!edit}
                  onClick={(e) => {
                    e.preventDefault();
                    setDirty(true);
                    setReasonEditable(!reasonEditable);
                  }}
                >
                  {t('Change')}
                </Link>
              </div>

              {/* <TextField
                id="outlined-size-small"
                variant="outlined"
                size="small"
                className={classes.input}
                disabled={!reasonEditable}
                onChange={({ target }) => {
                  setDirty(true);
                  setFormReason(target.value);
                }}
                value={formReason}
              /> */}

              <Multiselect
                options={multiSelectOptions.map((option) => ({
                  ...option,
                  key: option.value,
                  label: t(option.label),
                }))}
                placeHolder={t('Select Reason(s)')}
                className={classes.input}
                disabled={!reasonEditable}
                maxWidth={400}
                onChange={(e) => {
                  // setReasonError('');
                  setDirty(true);
                  setFormReason(e);
                }}
                value={formReason}
              />
            </div>

            {/* <div className={classes.inputWrapper}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formIsCoordinator}
                    onChange={({ target }) => {
                      setDirty(true);
                      setFormIsCoordinator(target.checked);
                    }}
                    name="beCoordinator"
                    color="primary"
                  />
                }
                label="Interested in becoming a coordinator?"
              />
            </div> */}
            {pods && pods.length > 0 && (
              <div className={classes.memberWrapper}>
                <Typography
                  variant="body1"
                  className={`${styles.subHeader} ${classes.memberHeader} ${styles.label}`}
                >
                  {t('Pod Membership')}
                </Typography>
                <div className={classes.podMembership}>
                  {isLoading && (
                    <CircularProgress
                      className={classes.progress}
                      color="inherit"
                    />
                  )}
                  {pods.map((p) => (
                    <div
                      className={classes.podMembershipItem}
                      key={'name' + p.name}
                    >
                      <Link
                        variant="body2"
                        className={`${classes.podName}`}
                        key={p.name}
                        href={'/pods/' + p.id}
                      >
                        {p.name}
                      </Link>

                      <Link
                        variant="body2"
                        className={`${classes.podLeave} ${styles.link}`}
                        hidden={!edit}
                        key={'leave' + p.name}
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setLeavePod(p);
                          setLeaveOpen(true);
                        }}
                      >
                        {t('Leave Pod')}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className={classes.inputWrapper}>
              <Button
                className={styles.deleteButton}
                disabled={!edit}
                onClick={() => {
                  setDeleteModalOpen(true);
                }}
              >
                {t('Delete Profile')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>

      <Dialog
        open={deleteModalOpen}
        className={classes.dialogWrapper}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle className={styles.dialogTitle} id="delete-dialog-title">
          {t('Delete this Account?')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={styles.dialogBody}
            id="delete-dialog-description"
          >
            {t('Are you sure you want to delete your account')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button
            className={styles.cancelButton}
            onClick={() => setDeleteModalOpen(false)}
            color="secondary"
          >
            {t('Cancel')}
          </Button>
          <Button
            className={`${styles.deleteButton} ${styles.leftIndent}`}
            onClick={deleteAccount}
            color="primary"
            autoFocus
          >
            {t('Delete Account')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={leaveOpen}
        className={classes.dialogWrapper}
        onClose={() => setLeaveOpen(false)}
        aria-labelledby="leave-dialog-title"
        aria-describedby="leave-dialog-description"
      >
        <DialogTitle className={styles.dialogTitle} id="leave-dialog-title">
          {t('Are you sure you want to leave this Pod?')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={styles.dialogBody}
            id="leave-dialog-description"
          >
            {t('This will completely remove you from')}{' '}
            <strong>{pod2Leave?.name}</strong>.{' '}
            {t(
              'Once removed, you can always rejoin this pod if you change your mind.'
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button
            className={styles.cancelButton}
            onClick={() => setLeaveOpen(false)}
            color="secondary"
          >
            {t('Cancel')}
          </Button>
          <Button
            className={styles.deleteButton}
            onClick={leavePod}
            color="primary"
            autoFocus
          >
            {t('Leave')}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        // anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          <AlertTitle>
            {t(
              severity[0].toUpperCase() +
                severity.substr(1, severity.length - 1)
            )}
          </AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default QuickProfile;
