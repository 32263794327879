import React, { useContext, useEffect, useState, Suspense } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter, Route } from 'react-router-dom';
// import axios from 'axios';
import Store from './state/store';
import StoreContext from './state/context/store';
import rootReducer from './state/reducers';
import GapLanding from './gap-pages/landing';
// import Footer from './components/Gage/Footer';
import Header from './components/Gap/Header';
import SurveyPopup from './components/Gap/Popup';
import CookieBanner from './components/CookieBanner';
import GapAbout from './gap-pages/about';
// import Contribute from './gap-pages/contribute';
import Feedback from './gap-pages/feedback';
// Add Select dependency to top bundle to avoid dependency ordering mismatch
// eslint-disable-next-line no-unused-vars
import { useCookie } from './lib/useCookie';
import { setMe } from './state/actions/me';
import { application } from './config';
import './Gap.scss';
import { CircularProgress } from '@material-ui/core';
import { SnackContextProvider } from './state/context/snack';
import { LayoutContextProvider } from './state/context/layout';
import { ListContextProvider } from './state/context/lists';

const Tracker = (props) => {
  const [, dispatch] = useContext(StoreContext);
  const [cookiesAccepted] = useCookie('cookiesAccepted', false);
  const [analyticsAccepted] = useCookie('gAnalyticsAccepted', true);

  useEffect(() => {
    if (cookiesAccepted && analyticsAccepted) {
      setMe(dispatch, { tracking: true });
    }
  }, [analyticsAccepted, cookiesAccepted, dispatch]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [props.history.location.pathname]);
  return null;
};

function Gap(props) {
  const [cookiesAccepted, setCookiesAccepted] = useCookie(
    'cookiesAccepted',
    false
  );
  const [analyticsAccepted, setAnalyticsAccepted] = useCookie(
    'gAnalyticsAccepted',
    true
  );
  const [popupStatus, setPopupStatus] = useState('show');

  useEffect(() => {
    console.log('Application config set to: ', application);
    console.log(
      'Environment variable set to: ',
      process.env.REACT_APP_APPLICATION
    );
    if (cookiesAccepted && analyticsAccepted) {
      ReactGA.initialize(process.env.REACT_APP_GAGE_ANALYTICS_ID, {
        debug: process.env.NODE_ENV !== 'production',
        gaOptions: {
          siteSpeedSampleRate: 100,
          allowAdFeatures: false,
        },
      });
    }
  }, [cookiesAccepted, analyticsAccepted]);

  const handleHidePopup = () => {
    setPopupStatus('hidden');
  };

  return (
    <div className="App">
      <LayoutContextProvider>
        <SnackContextProvider>
          <Store rootReducer={rootReducer}>
            <BrowserRouter>
              <Suspense
                fallback={
                  <CircularProgress
                    style={{
                      color: '#2c0040',
                      marginLeft: '20px',
                      marginTop: '20px',
                      width: '50px',
                      height: '50px',
                    }}
                  />
                }
              >
                <ListContextProvider>
                  <Route path="/" component={Tracker} />
                  <Header />
                  <Route exact path="/" component={GapLanding} />
                  <Route path="/about" component={GapAbout} />
                  {/* <Route path="/contribute" component={Contribute} /> */}
                  <Route path="/feedback" component={Feedback} />
                  {!cookiesAccepted && (
                    <CookieBanner
                      setCookiesAccepted={setCookiesAccepted}
                      setAnalyticsAccepted={setAnalyticsAccepted}
                      analyticsAccepted={analyticsAccepted}
                    />
                  )}
                  {popupStatus === 'show' && (
                    <SurveyPopup handleHidePopup={handleHidePopup} />
                  )}
                  {/* <Footer /> */}
                </ListContextProvider>
              </Suspense>
            </BrowserRouter>
          </Store>
        </SnackContextProvider>
      </LayoutContextProvider>
    </div>
  );
}

export default Gap;
