import * as React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import styles from './index.module.scss';
import { styled } from '@material-ui/styles';

const CustomToggleButton = styled(ToggleButton)({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#6E3694',
  },
});

const ChartToggleButton = ({ handleToggle, view }) => {
  return (
    <ToggleButtonGroup
      // color="primary"
      value={view}
      exclusive
      onChange={handleToggle}
      aria-label="View type"
      className={styles.buttonGroup}
    >
      <CustomToggleButton value="chart">View graph</CustomToggleButton>
      <CustomToggleButton value="table">View table</CustomToggleButton>
    </ToggleButtonGroup>
  );
};

export default ChartToggleButton;
